﻿import { Injectable, ErrorHandler } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { IServerStatus, IAlert, IAlertSummary } from './dashboard';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class DashboardService {

    constructor (private _http: HttpClient, private _authService: AuthService) {}

    getServerStatus(): Observable<IServerStatus[]> {
      if (!this._authService.isAuthenticated())
      {
        this._authService.logout()
      } 
     

        const token = localStorage.getItem('token');
        const httpHeaders = new HttpHeaders({'Authorization' : 'Bearer ' + token});
        const options = {headers: httpHeaders};
        return this._http.get<IServerStatus[]>(environment.webApiRoot + 'api/dashboard', options)
         //   .catch(this.handleError);
    }

    getTopAlert(): Observable<IAlert[]> {
      if (!this._authService.isAuthenticated())
      {
        this._authService.logout()
      } 
        const token = localStorage.getItem('token');
        const httpHeaders = new HttpHeaders({'Authorization' : 'Bearer ' + token});
        const options = {headers: httpHeaders};
        return this._http.get<IAlert[]>(environment.webApiRoot + 'api/dashboard/GetServerTopAlert', options)
    }

    getAlerts(): Observable<IAlert[]> {
      if (!this._authService.isAuthenticated())
      {
        this._authService.logout()
      } 
     

        const token = localStorage.getItem('token');
        const httpHeaders = new HttpHeaders({'Authorization' : 'Bearer ' + token});
        const options = {headers: httpHeaders};
        return this._http.get<IAlert[]>(environment.webApiRoot + 'api/dashboard/GetServerTopNAlerts', options)
         //   .catch(this.handleError);
    }

    getAlertSummary(): Observable<IAlertSummary[]> {
      if (!this._authService.isAuthenticated())
      {
        this._authService.logout()
      } 
     

        const token = localStorage.getItem('token');
        const httpHeaders = new HttpHeaders({'Authorization' : 'Bearer ' + token});
        const options = {headers: httpHeaders};
        return this._http.get<IAlertSummary[]>(environment.webApiRoot + 'api/dashboard/GetAlertSummary', options)
          //  .catch(this.handleError);
    }

    postClearAlert(id: number): Observable<number> {
      if (!this._authService.isAuthenticated())
      {
        this._authService.logout()
      } 
     

      const token = localStorage.getItem('token');
      const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
      const options = {headers: httpHeaders};
      return this._http.post<number>(environment.webApiRoot + "api/alert", JSON.stringify(id), options);

    }

    postLogTicket(id: number, importance: string): Observable<number> {
      if (!this._authService.isAuthenticated())
      {
        this._authService.logout()
      } 
     

      const token = localStorage.getItem('token');
      const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
      const options = {headers: httpHeaders};

      return this._http.post<number>(environment.webApiRoot + "api/alert/LogTicket", JSON.stringify({id: id, importance: importance}), options);

    }

    handleError(error: Response) {
        console.error(error);

        return Observable.throw(error);
    }
}

