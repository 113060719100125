﻿import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IServerStatus, IAlert, IAlertSummary } from './dashboard';
import { DashboardService } from './dashboard.service';
@Component({
    selector: 'dashboard-filter',
    templateUrl: './dashboardfilter.component.html',
    styleUrls: ['./dashboardfilter.component.css']
})

export class DashboardFilterComponent {
    servers: IServerStatus[];
    alerts: IAlert[];
    alertSummary: IAlertSummary[];
    interval: any;

    constructor(private _dashboardService: DashboardService) { }

    @Input()
    alertLevelFilter: number[] = [];

    @Output()
    dashboardFilterSelectionChanged: EventEmitter<number[]> = new EventEmitter<number[]>();

    onDashboardFilterChange(values: any) {

        if (values.currentTarget.checked) {
            if (this.alertLevelFilter.indexOf(parseInt(values.currentTarget.value)) == -1) {
                this.alertLevelFilter.push(parseInt(values.currentTarget.value));
            }
        } else {
            if (this.alertLevelFilter.indexOf(parseInt(values.currentTarget.value)) != -1) {
                this.alertLevelFilter.splice(this.alertLevelFilter.indexOf(parseInt(values.currentTarget.value)), 1);
            }
        }


        this.dashboardFilterSelectionChanged.emit(this.alertLevelFilter);
    }

        refreshData() {
        this._dashboardService.getServerStatus()
            .subscribe((serverData) => this.servers = serverData);

       this._dashboardService.getAlerts()
            .subscribe((alertData) => this.alerts = alertData);

        this._dashboardService.getAlertSummary()
            .subscribe((alertData) => this.alertSummary = alertData);
    }
}
