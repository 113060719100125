import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MustMatch } from './must-match.validator';
import { RegistrationService } from './registration.service';
import { Alert } from 'selenium-webdriver';
import { Register } from './registration';

@Component({
    selector: 'register',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.css'],
})

export class RegistrationComponent implements OnInit {
  registerForm: FormGroup;
    submitted = false;

/*   isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup; */


    constructor(private _router: Router, private _http: HttpClient, private _formBuilder: FormBuilder, private _registerservice: RegistrationService) { }

    ngOnInit() {


      this.registerForm = this._formBuilder.group({
        org_id: ['', Validators.required],
        company_name: ['', Validators.required],
        contact_firstname: ['', Validators.required],
        contact_lastname: ['', Validators.required],
        contact_email: ['', [Validators.required, Validators.email]],
        address_line1: ['', Validators.required],
        address_city: ['', Validators.required],
        address_province: ['', Validators.required],
        address_country: ['', Validators.required],
        address_postcode: ['', Validators.required],
        tel_countrycode: ['', Validators.required],
        tel_office: ['', [Validators.required]],
        terms_conditions: ['', [Validators.required]],
        username: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required]
    }, {
        validator: MustMatch('password', 'confirmPassword')
    });


/*
      this.firstFormGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required]
      });
      this.secondFormGroup = this._formBuilder.group({
        secondCtrl: ['', Validators.required]
      });
      this.thirdFormGroup = this._formBuilder.group({
        thirdCtrl: ['', Validators.required]
      }); */

    }
    get f() { return this.registerForm.controls; }
    onSubmit(registerForm: any) {
      this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        this._registerservice.getRegister(registerForm).subscribe(result => {
          if (result) {
            window.alert('Your account has been successfully created.');
            this._router.navigate(['login']);
          } else  {
            return;
          }
        });


    }
}





