import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Observable, pipe, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { jwtDecode } from "jwt-decode";





export interface token
{
  token: string;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
    refreshAccessToken(): any {
        throw new Error('Method not implemented.');
    }
  private message: string;

  constructor(private _router: Router, private _http: HttpClient) { }

  isAuthenticated(): boolean {
    return localStorage.getItem('token') != null && !this.isTokenExpired();
  }
  getExpirationDate(token: string): Date | null {
    try {
        const decoded: any = jwtDecode(token);
        if (decoded.exp === undefined) {
            return null;
        }
        const date = new Date(0); 
        date.setUTCSeconds(decoded.exp);
        return date;
    } catch (error) {
        console.error("Error decoding token:", error);
        return null;
    }
}
  isTokenExpired(): boolean {
   // return false;
    //token date comes from c# API at api/login endpoint
 if (this.getExpirationDate(localStorage.getItem('token')) >= new Date())
    {
      return false;
    }
    else {
      this.logout()
      return true;
    } 
  }


  TokenVerify(credentials: any)
  {
    let httpHeaders = new HttpHeaders({'Content-Type' : 'application/json'});
    let options = {headers: httpHeaders};

    var token = localStorage.getItem('token');
    return this._http.get<token>(environment.webApiRoot + "api/login", )
      .pipe(map(response => {
        if (response && response.token)
        {
          return true;
        }

        return false;
      }));
  }

  verifyCredentials(credentials: any)
  {
    let httpHeaders = new HttpHeaders({'Content-Type' : 'application/json'});
    let options = {headers: httpHeaders};

    return this._http.post<token>(environment.webApiRoot + "api/login", JSON.stringify(credentials), options)
      .pipe(map(response => {
        if (response && response.token)
        {
          localStorage.setItem('token', response.token);
          return true;
        }

        return false;
      }));
  }

  clear(): void {
    localStorage.clear();
  }

   logout(): void {
    this.clear();
    localStorage.clear();
    this._router.navigate(['/login']);
  }
}
