import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import * as internal from 'assert';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { catchError, map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
interface TreeNode {
  name: string;
  type?: string;
  children?: TreeNode[];
  expanded:boolean;
  searchProperty :string
}
export interface IAlertCustom {
  server_id?: number;
  disk_id?: number;
  highSeverityThreshold?: number;
  mediumSeverityThreshold?: number;
  lowSeverityThreshold?: number;
  isPercentage?: boolean;
}


export interface BoolRequest
{
  boolean :boolean
}

export interface JSON_ITEM
{
  configJSON : string,
  alert_type_id :number,
}

export interface ProcessorUtilisationAlert {
  highSeverityThreshold: number;
  mediumSeverityThreshold: number;
  lowSeverityThreshold: number;
  severityDuration: number;
  alertSeverityLevel: number;
}

export interface ProcessorUtilisationAlertCustom {
  server_id :number;
  highSeverityThreshold: number;
  mediumSeverityThreshold: number;
  lowSeverityThreshold: number;
  severityDuration: number;
  alertSeverityLevel: number;
}

export interface LongRunningJobAlert {
  highSeverityThreshold: number;
  mediumSeverityThreshold: number;
  lowSeverityThreshold: number;
  minimumDuration: number;
  alertSeverityLevel: number;
}

export interface LongRunningJobAlertCustom {
  server_id :number;
  instance_id :number;
  job_id: number;
  highSeverityThreshold: number;
  mediumSeverityThreshold: number;
  lowSeverityThreshold: number;
  minimumDuration: number;
  alertSeverityLevel: number;
}

export interface DiskCapacityAlert {
  highSeverityThreshold: number;
  mediumSeverityThreshold: number;
  lowSeverityThreshold: number;
  isPercentage: boolean;
}

export interface DiskCapacityAlertCustom {
  server_id :number;
  disk_id :number;
  highSeverityThreshold: number;
  mediumSeverityThreshold: number;
  lowSeverityThreshold: number;
  isPercentage: boolean;
}

export interface JobFailureAlert {
 severity :number,
 alertSeverityLevel :number
}

export interface JobFailureAlertCustom {
  server_id :number,
  instance_id :number,
  job_id :number,
  severity :number,
  alertSeverityLevel :number
}

//Error log

export interface ErrorLogAlert {
  highSeverityThreshold: number;
  mediumSeverityThreshold: number;
  lowSeverityThreshold: number;
  suppressionDuration: number;
}

export interface ErrorLogAlertCustom {
  server_id?: number;
  instance_id?: number; 
  highSeverityThreshold: number;
  mediumSeverityThreshold: number;
  lowSeverityThreshold: number;
  suppressionDuration: number;
}

//Blocking 
export interface BlockingAlert {
  highSeverityDuration: number;
  mediumSeverityDuration: number;
  lowSeverityDuration: number;
}

export interface BlockingAlertCustom {
  server_id?: number;
  instance_id?: number; 
  highSeverityDuration: number;
  mediumSeverityDuration: number;
  lowSeverityDuration: number;
}

//Log Backup:
export interface LogBackupAlert {
  highSeverityThreshold: number;
  mediumSeverityThreshold: number;
  lowSeverityThreshold: number;
  severityDuration :number
}

export interface LogBackupAlertCustom {
  server_id?: number;
  instance_id?: number; 
  database_id? :number;
  highSeverityThreshold: number;
  mediumSeverityThreshold: number;
  lowSeverityThreshold: number;
  severityDuration :number

}

//Availability Group Health:
export interface AvailabilityGroupHealthAlert {
  highSeverityValue: number;
  mediumSeverityValue: number;
  lowSeverityValue: number; 
}

export interface AvailabilityGroupHealthAlertCustom {
  server_id: number;
  instance_id: number; 
  highSeverityValue: number;
  mediumSeverityValue: number;
  lowSeverityValue: number;
}

//agent configuration

export interface AgentConfig {
  interval_seconds: number;
  is_enabled: boolean;
}

export interface AgentConfigOption {
  configuration_id :number;
  configuration_name:string; 
}






export interface DefaultAllRecord
{
  server_id :number,
  instance_id :number,
}


export interface getDisks {
  disk_id :number;
  disk_label :string;
}
export interface getJobs {
  sqlserver_agent_job_id :number;
  name :string;
}


export interface ServerHeartbeatAlert{
  severity: number
  severityDuration: number;
  alertSeverityLevel: number;
}

export interface ServerHeartbeatAlertCustom{
  server_id :number;
  severity: number
  severityDuration: number;
  alertSeverityLevel: number;
}

export interface MonitorHeartbeatAlert{
  severity: number
  severityDuration: number;
  alertSeverityLevel: number;
}

export interface MonitorHeartbeatAlertCustom{
  licence_key:string;
  severity: number
  severityDuration: number;
  alertSeverityLevel: number;
}

export interface FullBackupAlert{
  severity :number;
  severityDuration :number;
}

export interface FullBackupAlertCustom{
  severity :number;
  severityDuration :number;
  server_id :number;
  instance_id :number;
  database_id :number;
}

export interface Database{
  database_id: number,
  instance_id: number,
  name :string
}

interface TreeNode {
  name: string;
  type?: string;
  children?: TreeNode[];
  expanded: boolean ;
  searchProperty :string;
}


@Injectable({
  providedIn: 'root'
})
export class ConfigNavigationServiceService {
 
  //tab selection between Default and Custom (server specific ) settings.
  private tabSelectedSource = new Subject<{ tabName: string, subtab: string }>();
  tabSelected$ = this.tabSelectedSource.asObservable();

  selectTab(tabName: string, subtab :string) {
    this.tabSelectedSource.next({ tabName, subtab });
  }

  private pageState = new BehaviorSubject<any | null>(null);
  private reload =  new BehaviorSubject<void>(null);
  private navigator =  new Subject<{node :TreeNode}>();

  // Expose pageState as an observable for components to subscribe to
  pageState$ = this.pageState.asObservable();

  public breadcrumb = new BehaviorSubject<string | null>(null);
  breadcrumb$ = this.breadcrumb.asObservable();
   
  navigator$ = this.navigator.asObservable();

  constructor(private http: HttpClient) { }

  public lastReload = new Date();

  private handleError(error: any) {
    console.error('There was an error!', error);
    return throwError(error);
  }

  getProcessorUtilisationAlert(): Observable<ProcessorUtilisationAlert> { //default setting
    return this.http.get<ProcessorUtilisationAlert>(`${environment.webApiRoot}api/config/GetAlertProcessorUtilisation`)
    .pipe(catchError(this.handleError));
  }

  getProcessorUtilisationAlertCustom(server_id :number): Observable<ProcessorUtilisationAlertCustom> 
  {
    const url = `${environment.webApiRoot}api/config/GetAlertProcessorUtilisationCustom`;
    
    return this.http.post<ProcessorUtilisationAlertCustom>(url, {server_id:server_id})
    .pipe(catchError(this.handleError));
  }

  getLongRunningJobAlert(): Observable<LongRunningJobAlert> { //default setting
    return this.http.get<LongRunningJobAlert>(`${environment.webApiRoot}api/config/GetAlertLongRunningJob`)
    .pipe(catchError(this.handleError));
  }

  getLongRunningJobAlertCustom(server_id :number, instance_id :number): Observable<LongRunningJobAlertCustom[]> 
  {
    const url = `${environment.webApiRoot}api/config/GetAlertLongRunningJobCustom`;
    
    return this.http.post<LongRunningJobAlertCustom[]>(url, {server_id:server_id, instance_id:instance_id})
    .pipe(catchError(this.handleError));
  }
  //error log
  getErrorLogAlert(): Observable<ErrorLogAlert> { //default setting
    return this.http.get<ErrorLogAlert>(`${environment.webApiRoot}api/config/GetAlertErrorLog`)
    .pipe(catchError(this.handleError));
  }

  getErrorLogAlertCustom(server_id: number, instance_id): Observable<ErrorLogAlertCustom[]> {
    const url = `${environment.webApiRoot}api/config/GetAlertErrorLogCustom`;
    
    return this.http.post<ErrorLogAlertCustom[]>(url, { server_id: server_id, instance_id:instance_id })
    .pipe(catchError(this.handleError));
  }

  //blocking
  getBlockingAlert(): Observable<BlockingAlert> { //default setting
    return this.http.get<BlockingAlert>(`${environment.webApiRoot}api/config/GetAlertBlocking`)
    .pipe(catchError(this.handleError));
  }

  getBlockingAlertCustom(server_id: number, instance_id :number): Observable<BlockingAlertCustom[]> {
    const url = `${environment.webApiRoot}api/config/GetAlertBlockingCustom`;
    
    return this.http.post<BlockingAlertCustom[]>(url, { server_id: server_id, instance_id:instance_id})
    .pipe(catchError(this.handleError));
  }

  //log backup
  getLogBackupAlert(): Observable<LogBackupAlert> { //default setting
    return this.http.get<LogBackupAlert>(`${environment.webApiRoot}api/config/GetAlertLogBackup`)
    .pipe(catchError(this.handleError));
  }

  getLogBackupAlertCustom(server_id: number,  instance_id :number): Observable<LogBackupAlertCustom[]> {
    const url = `${environment.webApiRoot}api/config/GetAlertLogBackupCustom`;
    
    return this.http.post<LogBackupAlertCustom[]>(url, { server_id: server_id, instance_id:instance_id })
    .pipe(catchError(this.handleError));
  }

  //availability group health
  getAvailabilityGroupHealthAlert(): Observable<AvailabilityGroupHealthAlert> { //default setting
    return this.http.get<AvailabilityGroupHealthAlert>(`${environment.webApiRoot}api/config/GetAlertAvailabilityGroupHealth`)
    .pipe(catchError(this.handleError));
  }

  getAvailabilityGroupHealthAlertCustom(server_id: number, instance_id:number): Observable<AvailabilityGroupHealthAlertCustom[]> {
    const url = `${environment.webApiRoot}api/config/GetAlertAvailabilityGroupHealthCustom`;
    
    return this.http.post<AvailabilityGroupHealthAlertCustom[]>(url, { server_id: server_id, instance_id:instance_id})
    .pipe(catchError(this.handleError));
  }


  //Disk Capacity
  getDisks(server_id :number): Observable<getDisks[]> { //get disk_ids and the associated label for the requested server_id.
    const url = `${environment.webApiRoot}api/config/GetDisks`;
    
    return this.http.post<getDisks[]>(url, {server_id:server_id})
    .pipe(catchError(this.handleError));
  }
  getDiskCapacityAlert(): Observable<DiskCapacityAlert> { //default setting
    return this.http.get<DiskCapacityAlert>(`${environment.webApiRoot}api/config/GetAlertDiskCapacity`);
  }

  getDiskCapacityAlertCustom(server_id :number): Observable<DiskCapacityAlertCustom[]> 
  {
    const url = `${environment.webApiRoot}api/config/GetAlertDiskCapacityCustom`;
    
    return this.http.post<DiskCapacityAlertCustom[]>(url, {server_id:server_id})
    .pipe(catchError(this.handleError));
  }

  //sever heartbeat
  GetAlertServerHeartbeat(): Observable<ServerHeartbeatAlert> { //default setting
    return this.http.get<ServerHeartbeatAlert>(`${environment.webApiRoot}api/config/GetAlertServerHeartbeat`);
  }

  GetAlertServerHeartbeatCustom(server_id :number): Observable<ServerHeartbeatAlertCustom> 
  {
    const url = `${environment.webApiRoot}api/config/GetAlertServerHeartbeatCustom`;
    
    return this.http.post<ServerHeartbeatAlertCustom>(url, {server_id:server_id})
    .pipe(catchError(this.handleError));
    
  }
 //monitor heartbeat

 GetAlertMonitorHeartbeat(): Observable<MonitorHeartbeatAlert> { //default setting
  return this.http.get<MonitorHeartbeatAlert>(`${environment.webApiRoot}api/config/GetAlertMonitorHeartbeat`);
}

GetAlertMonitorHeartbeatCustom(licence_key :string): Observable<MonitorHeartbeatAlertCustom> 
{
  const url = `${environment.webApiRoot}api/config/GetAlertMonitorHeartbeatCustom`;
  
  return this.http.post<MonitorHeartbeatAlertCustom>(url, {licence_key:licence_key})
  .pipe(catchError(this.handleError));
  
}
//Full Backups

GetDatabase(server_id :number, instance_id :number): Observable<Database[]> {
  const url = `${environment.webApiRoot}api/config/GetDatabase`;
  
  return this.http.post<Database[]>(url, {server_id: server_id, instance_id:instance_id})
  .pipe(catchError(this.handleError));
}

GetLogDatabase(server_id :number, instance_id :number): Observable<Database[]> {    //TODO: Need to retrieve recovery model from agent, then we can retrieve the value here
  const url = `${environment.webApiRoot}api/config/GetDatabase`;                    //When recovery model retreval is implemented, new endpoint to be created that returns only
                                                                                    //databases in SIMPLE recovery model. Until then, use existing database endpoint.
  return this.http.post<Database[]>(url, {server_id: server_id, instance_id:instance_id}) 
  .pipe(catchError(this.handleError));
}

GetAlertFullBackups(): Observable<FullBackupAlert> { //default setting
  return this.http.get<FullBackupAlert>(`${environment.webApiRoot}api/config/GetAlertFullBackup`)
  .pipe(catchError(this.handleError));
}

GetAlertFullBackupsCustom(server_id :number, instance_id :number): Observable<FullBackupAlertCustom[]> {
  const url = `${environment.webApiRoot}api/config/GetAlertFullBackupCustom`

  return this.http.post<FullBackupAlertCustom[]>(url, {server_id:server_id, instance_id:instance_id})
  .pipe(catchError(this.handleError));
}




//Job Failure
getJobs(server_id :number, instance_id :number): Observable<getJobs[]> { 
  const url = `${environment.webApiRoot}api/config/GetJobs`;
  
  return this.http.post<getJobs[]>(url, {instance_id:instance_id})
  .pipe(catchError(this.handleError));
}
GetAlertJobFailure(): Observable<JobFailureAlert> { //default setting
  return this.http.get<JobFailureAlert>(`${environment.webApiRoot}api/config/GetAlertJobFailure`)
  .pipe(catchError(this.handleError));
}

GetAlertJobFailureCustom(server_id :number, instance_id :number): Observable<JobFailureAlertCustom[]> 
{
  const url = `${environment.webApiRoot}api/config/GetAlertJobFailureCustom`;
  
  return this.http.post<JobFailureAlertCustom[]>(url, {server_id:server_id, instance_id:instance_id})
  .pipe(catchError(this.handleError));
}

GetDefaultAllRecordDisk(alert_type_id: number, server_id :number): Observable<DiskCapacityAlertCustom> {
  const url = `${environment.webApiRoot}api/config/GetDefaultAllRecord`;
  ////console.log("Preparing to send request to:", url);



  return this.http.post<DiskCapacityAlertCustom>(url, { alertType: alert_type_id, server_id:server_id})
  .pipe(catchError(this.handleError));

}

GetDefaultAllRecordJobFailure(alert_type_id: number, server_id :number, instance_id: number): Observable<JobFailureAlertCustom> {
  const url = `${environment.webApiRoot}api/config/GetDefaultAllRecord`;
  ////console.log("Preparing to send request to:", url);



  return this.http.post<JobFailureAlertCustom>(url, { alertType: alert_type_id, server_id:server_id, instance_id:instance_id})
  .pipe(catchError(this.handleError));

}

GetDefaultAllRecordFullBackup(alert_type_id: number, server_id :number, instance_id: number): Observable<FullBackupAlertCustom> {
  const url = `${environment.webApiRoot}api/config/GetDefaultAllRecord`;
  ////console.log("Preparing to send request to:", url);



  return this.http.post<FullBackupAlertCustom>(url, { alertType: alert_type_id, server_id:server_id, instance_id:instance_id})
  .pipe(catchError(this.handleError));

}


GetDefaultAllRecordLongRunningJob(alert_type_id: number, server_id :number, instance_id: number): Observable<LongRunningJobAlertCustom> {
  const url = `${environment.webApiRoot}api/config/GetDefaultAllRecord`;
  ////console.log("Preparing to send request to:", url);



  return this.http.post<LongRunningJobAlertCustom>(url, { alertType: alert_type_id, server_id:server_id, instance_id:instance_id})
  .pipe(catchError(this.handleError));

}

GetDefaultAllRecordLogBackup(alert_type_id: number, server_id :number, instance_id: number): Observable<LogBackupAlertCustom> {    //repeated due to datatypes changing. 
  const url = `${environment.webApiRoot}api/config/GetDefaultAllRecord`;
  ////console.log("Preparing to send request to:", url);



  return this.http.post<LogBackupAlertCustom>(url, { alertType: alert_type_id, server_id:server_id, instance_id:instance_id})
  .pipe(catchError(this.handleError));

}





//agent configuration
GetAgentConfiguration(server_id :number, instance_id :number, configuration_id :number): Observable<AgentConfig> { 
  return this.http.post<AgentConfig>(`${environment.webApiRoot}api/config/GetAgentConfigurationSettings`, {instance_id :instance_id, server_id :server_id, configuration_id:configuration_id})
  .pipe(catchError(this.handleError));
}

SaveAgentConfiguration(server_id :number, instance_id :number, configuration_id :number, interval_seconds :number, is_enabled :boolean): Observable<void> { 
  return this.http.post<void>(`${environment.webApiRoot}api/config/SaveAgentConfiguration`, { server_id :server_id, instance_id :instance_id, configuration_id:configuration_id, interval_seconds:interval_seconds, is_enabled:is_enabled})
  .pipe(catchError(this.handleError));
}
DeleteAgentConfiguration(server_id :number, instance_id :number, configuration_id :number): Observable<void> { 
  return this.http.post<void>(`${environment.webApiRoot}api/config/DeleteAgentConfiguration`, { instance_id :instance_id,server_id :server_id, configuration_id:configuration_id})
  .pipe(catchError(this.handleError));
}


GetAgentConfigurationOptions(type :string): Observable<AgentConfigOption[]>
{
  return this.http.post<AgentConfigOption[]>(`${environment.webApiRoot}api/config/GetAgentConfigurationOptions`, {type:type})
  .pipe(catchError(this.handleError));
}



SearchForJSON(configJSON: string, alert_type_id: number): Observable<boolean> {
  const url = `${environment.webApiRoot}api/config/SearchForJSON`;
  ////console.log("SENDING SEARCH REQ");
  
  return this.http.post<boolean>(url, { configJSON: configJSON, alert_type_id: alert_type_id }).pipe(
    tap((response: boolean) => {
      ////console.log("Response:", response);
    }),
    catchError(this.handleError)
  );
}


  getOrganisationData(): Observable<TreeNode[]> {
    const url = `${environment.webApiRoot}api/config/GetOrganisationData`;
    
    return this.http.get<any[]>(url).pipe(
      map(data => this.transformToTreeNodes(data)),
      //tap(transformedData => ////console.log(transformedData))  // Logging of transformed data
      catchError(this.handleError)
    )
    
  }

  triggerReload() {
   const now = new Date();
      this.lastReload = now;
      this.reload.next();   //Main reload point
  
  }

  autoReload() {
    const now = new Date();
    const thirtySecondsAgo = new Date(now.getTime() - 30 * 1000);

    if (this.lastReload < thirtySecondsAgo) {
      this.triggerReload()
      ////console.log('last reload before this one was ' + this.lastReload)
    } 
  }
  
  getreload$(): Observable<void> {
    return this.reload.asObservable();
  }


  private transformToTreeNodes(data: any[]): TreeNode[] {
    return data.sort((a, b) => a.name.localeCompare(b.name)).map(org => { //order by organisation name
  
      const licensesNodes: TreeNode[] = org.licenses && org.licenses.length > 0 ? org.licenses.map((license: any) => {
        const serversNodes: TreeNode[] = license.servers && license.servers.length > 0 ? license.servers.map((server: any) => {
          const instancesNodes: TreeNode[] = server.instances && server.instances.length > 0 ? server.instances.map((instance: any) => ({
            sqlserver_instance_id: instance.sqlserver_instance_id,
            server_id: instance.server_id,           
            name: instance.name == "" ? "MSSQLSERVER"  : instance.name,
            collection_date: instance.collection_date,
            port: instance.port,
            type: 'instance',
            expanded: false,
            serverName: server.name,
            license_name: license.description && license.description.trim() != "" ? license.description : license.licence_key.trim(),
            organization_name: org.name,
            searchProperty: instance.sqlserver_instance_id,
            isNew:false,
          })) : [{
            sqlserver_instance_id: 0,
            server_id: server.server_id,   
            name: "addNewInstance",
            collection_date: null,
            port: 0,
            type: 'instance',
            expanded: false,
            serverName: server.name,
            license_name: license.description && license.description.trim() != "" ? license.description : license.licence_key.trim(),
            organization_name: org.name,
            searchProperty: 0,
            isNew:false,
          }];
  
          return {
            name: server.name.split("-ems.public")[0].split(".af-south")[0].trim(), // Shorten server name
            children: instancesNodes,
            type: 'server',
            server_id: server.server_id,
            organisation_id: license.organisation_id,
            license_key: license.licence_key.trim(),
            is_active: server.is_active,
            expanded: false,
            license_name: license.description && license.description.trim() != "" ? license.description : license.licence_key.trim(),
            organization_name: org.name,
            searchProperty: server.server_id,
            isNew:false,
          };
        }) : [{
          name: "addNewServer",
          children: [],
          type: 'server',
          server_id: null,
          organisation_id: org.organisation_id,
          license_key: license.licence_key.trim(),
          is_active: license.is_active,
          expanded: false,
          license_name: license.description && license.description.trim() !== "" ? license.description : license.licence_key.trim(),
          organization_name: org.name,
          searchProperty: null,
          isNew:false,
        }];
  
        return {
          name: license.licence_key.trim(),
          children: serversNodes,
          type: 'license',
          description: license.description,
          active: license.is_active,
          organization: license.organisation_id,
          organization_name: org.name,
          enable: false,
          expanded: false,
          searchProperty: license.licence_key.trim(),
          isNew:false,
        };
      }) : [{
        name: "addNewLicense",
        children: [],
        type: 'license',
        description: null,
        active: false,
        organization: org.organisation_id,
        organization_name: org.name,
        enable: false,
        expanded: false,
        searchProperty: "addNewLicense",
        isNew:false,
      }];
  
      return {
        name: org.name.trim(),
        btNet: org.btNet,
        organization_id: org.organisation_id,
        children: licensesNodes,
        expanded: false,
        type: 'organisation',
        searchProperty: org.name.trim(),
        isNew:false,
      };
    });
  }
   
  
  getPageState(): any | null {
    return this.pageState.getValue();
  }

  setPageState(child: any): void {
    // Updates the BehaviorSubject with the new state
    this.pageState.next(child);

    this.updateBreadcrumb(child);
    ////console.log('page state')
    ////console.log(this.pageState.getValue());
  }

  updateBreadcrumb(node :TreeNode)
  { //updates the breadcrumb string. Must be queried after update by subscriber.
    //console.log(node)

    let crumb :string = "";

    if (node.type == 'organisation')
    {
      crumb = node.name;
    } 
    
    if (node.type == 'server')
    {
      
    }
      
    if (node.type == 'instance')
    {
      
    }

    //console.log("Set crumb to: " + crumb)



  }

  saveServer(name, org_id, license_key, is_active, server_id)
  {// JSON.stringify({id: id, importance: importance}
    ////console.log('saving the server');
    const body = {
      organisation_id: org_id,
      name: name,
      license_key: license_key,
      is_active: is_active,
      server_id:server_id
    };
    ////console.log('body is', body)
    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
    const options = {headers: httpHeaders};


    ////console.log("api/config/saveServer");
    const url = `${environment.webApiRoot}api/config/SaveServer`;
    
    this.http.post<any>(url, body, options).subscribe(
      data => {
        //////console.log(data);
        
      },
      error => {
        console.error('There was an error!', error);
      }
    );
  }


  saveAlertToDB(configJSON :string, alert_type_id :number)
  {
    //console.log('saving alert');

    const body = {
      configJSON: configJSON,
      alert_type_id: alert_type_id
    };

    console.log('body is', body)

    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
    const options = {headers: httpHeaders};

    console.log("api/config/UpsertAlertToDB");
    const url = `${environment.webApiRoot}api/config/UpsertAlertToDB`;

    this.http.post<any>(url, body, options).subscribe(
      data => {
        ////console.log(data);
      },
      error => {
        console.error('There was an error!', error);
      }
    );

  }

  deleteAlertFromDB(configJSON :string, alert_type_id :number)
  {
    ////console.log('deleting alert');

    const body = {
      configJSON: configJSON,
      alert_type_id: alert_type_id
    };

    ////console.log('body is', body)

    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
    const options = {headers: httpHeaders};

    ////console.log("api/config/DeleteAlertFromDB");
    const url = `${environment.webApiRoot}api/config/DeleteAlertFromDB`;

    this.http.post<any>(url, body, options).subscribe(
      data => {
        //////console.log(data);
      },
      error => {
        console.error('There was an error!', error);
      }
    );

  }

  deleteSearchFromDB(configJSON :string, alert_type_id :number)
  {
    ////console.log('deleting search');

    const body = {
      configJSON: configJSON,
      alert_type_id: alert_type_id
    };

    ////console.log('body is', body)

    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
    const options = {headers: httpHeaders};

    ////console.log("api/config/DeleteSearchFromDB");
    const url = `${environment.webApiRoot}api/config/DeleteSearchFromDB`;

    this.http.post<any>(url, body, options).subscribe(
      data => {
        //////console.log(data);
      },
      error => {
        console.error('There was an error!', error);
      }
    );

  }

  deleteServer(name, org_id, license_key, is_active, server_id)
  {// JSON.stringify({id: id, importance: importance}
    ////console.log('deleting the server', name);
    const body = {
      organisation_id: org_id,
      name: name,
      license_key: license_key,
      is_active: is_active,
      server_id :server_id
    };
    //console.log(body)
    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
    const options = {headers: httpHeaders};



    ////console.log("api/config/deleteServer");
    const url = `${environment.webApiRoot}api/config/DeleteServer`;
    
    this.http.post<any>(url, body, options).subscribe(
      data => {
        //////console.log(data);
      },
      error => {
        console.error('There was an error!', error);
      }
    );
  }

  deleteOrganization(id)
  {// JSON.stringify({id: id, importance: importance}
    ////console.log('deleting the server', name);
    const body = {
      organisation_id: id
    };
    //console.log(body)
    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
    const options = {headers: httpHeaders};



    ////console.log("api/config/deleteServer");
    const url = `${environment.webApiRoot}api/config/DeleteOrganization`;
    
    this.http.post<any>(url, body, options).subscribe(
      data => {
        //////console.log(data);
      },
      error => {
        console.error('There was an error!', error);
      }
    );
  }



  saveInstance(server_id, sqlserver_instance_id, name, port)
  {// JSON.stringify({id: id, importance: importance}
    ////console.log('saving the insance');
    const body = { 
      server_id: server_id,
      sqlserver_instance_id: sqlserver_instance_id,
      name: name,
      port: port

    };
    //console.log(body)
    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
    const options = {headers: httpHeaders};


    //console.log("api/config/saveInstance");
    const url = `${environment.webApiRoot}api/config/SaveInstance`;
    
    this.http.post<any>(url, body, options).subscribe(
      data => {
        //////console.log(data);
      },
      error => {
        console.error('There was an error!', error);
      }
    );
  }

  deleteInstance(sqlserver_instance_info_id)
  {// JSON.stringify({id: id, importance: importance}
    ////console.log('deleting the instance', sqlserver_instance_info_id);
    const body = {
      sqlserver_instance_info_id: sqlserver_instance_info_id
    };
    ////console.log(body)
    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
    const options = {headers: httpHeaders};


    ////console.log("api/config/deleteInstance");
    const url = `${environment.webApiRoot}api/config/DeleteInstance`;
    
    this.http.post<any>(url, sqlserver_instance_info_id, options).subscribe(
      data => {
        //////console.log(data);
      },
      error => {
        console.error('There was an error!', error);
      }
    );
  }

  deleteLicense(license_key: string) {
    ////console.log('deleting the license', license_key);
    const body = {
      LicenseKey: license_key
    };
    ////console.log(body)
    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
    const options = {headers: httpHeaders};


    ////console.log("api/config/deleteLicense");
    const url = `${environment.webApiRoot}api/config/DeleteLicense`;
    
    this.http.post<any>(url, body, options).subscribe(
      data => {
        //////console.log(data);
      },
      error => {
        console.error('There was an error!', error);
      }
    );
  }


  saveLicense(license_key :string, description :string, is_active :boolean, organisation_id :number)
  {
    const body = {
      licence_key: license_key,
      description: description,
      is_active: is_active,
      organisation_id: organisation_id
    };

    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
    const options = {headers: httpHeaders};


    const url = `${environment.webApiRoot}api/config/SaveLicense`;
    ////console.log(url, body) ;

    this.http.post<any>(url, body, options).subscribe(
      data => {
        //////console.log(data);
      },
      error => {
        console.error('There was an error!', error);
      }
    );
  }


  saveOrganization(organization_id :number, btNet :number, name :string)
  {
    const body = {
    organisation_id: Number(organization_id),
    btNet: Number(btNet),
    name: name
    };

    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
    const options = {headers: httpHeaders};


    const url = `${environment.webApiRoot}api/config/SaveOrganization`;
    //console.log(url, body) ;

    this.http.post<any>(url, body, options).subscribe(
      data => {
        //////console.log(data);
      },
      error => {
        console.error('There was an error!', error);
      }
    );
  }
}
