﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { IAlert } from './alert';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AlertService {

    constructor(private _http: HttpClient, private _authService: AuthService) { }

    getAlertList(server_id: number, source_id: number, page_number: number, rows_per_page: number): Observable<IAlert[]> {

        if (!this._authService.isAuthenticated())
        {
          this._authService.logout()
        } 
       


        if (!server_id) { server_id = 0; }
        if (!source_id) { source_id = 0; }
        const token = localStorage.getItem('token');
        const httpHeaders = new HttpHeaders({'Authorization' : 'Bearer ' + token});
        const options = {headers: httpHeaders};
        return this._http.get<IAlert[]>(environment.webApiRoot + "api/alert/GetAlertList?id=" + server_id + "&sid=" + source_id + "&p=" + page_number + "&rpp=" + rows_per_page, options)
            .catch(this.handleError);
    }

    getAlertDetail(alert_id: number): Observable<IAlert> {

        if (!this._authService.isAuthenticated())
        {
          this._authService.logout()
        } 
       

        if (!alert_id) { alert_id = 0; }
        const token = localStorage.getItem('token');
        const httpHeaders = new HttpHeaders({'Authorization' : 'Bearer ' + token});
        const options = {headers: httpHeaders};
        return this._http.get<IAlert>(environment.webApiRoot + "api/alert/GetAlertDetail?id=" + alert_id, options)
            .catch(this.handleError);
    }


    postClearAlert(id: number): Observable<number> {

        if (!this._authService.isAuthenticated())
        {
          this._authService.logout()
        } 
       

        const token = localStorage.getItem('token');
        const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token});
        const options = {headers: httpHeaders};
        return this._http.post<number>(environment.webApiRoot + "api/alert", JSON.stringify(id), options);


    }

    handleError(error: Response) {
        console.error(error);
        return Observable.throw(error);
    }
}

