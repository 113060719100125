﻿import { Component, OnInit } from "@angular/core";
import { IAlert } from './alert';
import { AlertService } from './alert.service';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'alert-list',
    templateUrl: './alertlist.component.html',
    styleUrls: ['./alertlist.component.css'],
})

export class AlertListComponent implements OnInit {

    interval: any;
    alerts: IAlert[];

    selectAll: boolean = false;

    pageNumber: number;
    rowsPerPage: number;

    sub: any;
    id: number;
    sid: number;

    constructor(private _router: Router, private _alertService: AlertService, private _Activatedroute: ActivatedRoute) { }


    ngOnInit() {
        this.sub = this._Activatedroute.params.subscribe(params => {
            this.id = params['id'];
            this.sid = params['sid'];
        });
        this.pageNumber = 1;
        this.rowsPerPage = 100;
        this.refreshData();
        this.interval = setInterval(() => {
            if (localStorage.getItem('token') == null) {
                this._router.navigate(['login']);
                /* if (!this._authService.isAuthenticated()){
                    this._router.navigate(['login']);
                    }  // See login.component.ts for more info  */
            } else {
            this.refreshData();
        }}, 60000);

    }

    refreshData() {
        this._alertService.getAlertList(this.id, this.sid, this.pageNumber, this.rowsPerPage)
            .subscribe((alertData) => {
                const tmpAlerts = this.alerts;
                this.alerts = alertData;
                let i = 0;
                // check for if value defined
                if (tmpAlerts) {
                  while (i < tmpAlerts.length) {
                    this.alerts.find(x => x.id == tmpAlerts[i].id).isChecked = tmpAlerts[i].isChecked;
                    i++;
                  }
                }

               // this.alerts.sort((a, b) => a.alertLevel - b.alertLevel); - to sort by severity instead of time.

            });
    }

    trackByAlertId(index: number, alert: IAlert): number {
        return alert.id;
    }

    nextPage() {
        this.pageNumber++;
        this.refreshData();
    }

    prevPage() {
        this.pageNumber--;
        this.refreshData();
    }

    checkIfAllSelected() {
        this.selectAll = this.alerts.every(function(item: any) {
            return item.selected == true;
          });
        
    }

    CheckBoxClick(event: any) {
        event.stopPropagation();
    }

    clearAlert() {
        for (let i = 0; i < this.alerts.length; i++) {
            if (this.alerts[i].isChecked) {
                this._alertService.postClearAlert(this.alerts[i].id).subscribe(data => {  });
            }
        }

        this.refreshData();
    }

    toggleSelectAll() {
        for (let i = 0; i < this.alerts.length; i++) {
            this.alerts[i].isChecked = this.selectAll;
        }
    }
}
