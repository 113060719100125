import { DataSource } from '@angular/cdk/collections';
import { BlockScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { load } from '@angular/core/src/render3';
import { DateAdapter, MatDialog } from '@angular/material';
import { config } from 'process';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigNavigationServiceService,FullBackupAlert,Database, DefaultAllRecord, BoolRequest, FullBackupAlertCustom, DiskCapacityAlert, DiskCapacityAlertCustom, JobFailureAlert, JobFailureAlertCustom, LongRunningJobAlert, LongRunningJobAlertCustom, MonitorHeartbeatAlert, MonitorHeartbeatAlertCustom, ProcessorUtilisationAlert, ProcessorUtilisationAlertCustom, ServerHeartbeatAlert, ServerHeartbeatAlertCustom, getDisks, getJobs, ErrorLogAlert, ErrorLogAlertCustom, BlockingAlert, BlockingAlertCustom, LogBackupAlert, LogBackupAlertCustom, AvailabilityGroupHealthAlert, AvailabilityGroupHealthAlertCustom, AgentConfigOption, AgentConfig} from 'src/app/config-navigation/config-navigation-service.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';

class DiskItem {
  highSeverityThreshold :Number
  isPercentage :boolean
  lowSeverityThreshold :Number
  mediumSeverityThreshold :Number
  server_id :Number

  constructor(highSeverityThreshold, isPercentage, lowSeverityThreshold, mediumSeverityThreshold, server_id) {
    this.highSeverityThreshold = highSeverityThreshold;
    this.isPercentage = isPercentage;
    this.lowSeverityThreshold = lowSeverityThreshold;
    this.mediumSeverityThreshold = mediumSeverityThreshold;
    this.server_id = server_id;
  }

  static areAllItemsEqual(items) {
    if (items.length === 0) return false;
    const keysToCheck = [
      'highSeverityThreshold',
      'isPercentage',
      'lowSeverityThreshold',
      'mediumSeverityThreshold',
      'server_id'
    ];
    return items.every(item =>
      keysToCheck.every(key => item[key] === items[0][key])
    );
  }
}
class JobItem {
  server_id: number;
  instance_id: number;
  severity: number;
  alertSeverityLevel: number;

  constructor(server_id: number, instance_id: number, severity: number, alertSeverityLevel: number) {
    this.server_id = server_id;
    this.instance_id = instance_id;
    this.severity = severity;
    this.alertSeverityLevel = alertSeverityLevel;
  }

  static areAllItemsEqual(items: JobItem[]): boolean {
    if (items.length === 0) return false;
    const keysToCheck = [
      'server_id',
      'instance_id',
      'severity',
      'alertSeverityLevel'
    ];
    return items.every(item =>
      keysToCheck.every(key => item[key] === items[0][key])
    );
  }
}

class FullBackupItem {
  server_id: number;
  instance_id: number;
  severity: number;
  severityDuration: number;

  constructor(server_id: number, instance_id: number, severity: number, severityDuration: number) {
    this.server_id = server_id;
    this.instance_id = instance_id;
    this.severity = severity;
    this.severityDuration = severityDuration;
  }

  static areAllItemsEqual(items: FullBackupItem[]): boolean {
    if (items.length === 0) return false;
    const keysToCheck = [
      'server_id',
      'instance_id',
      'severity',
      'severityDuration'
    ];
    return items.every(item =>
      keysToCheck.every(key => item[key] === items[0][key])
    );
  }
}


@Component({
  selector: 'app-config-page-display',
  templateUrl: './config-page-display.component.html',
  styleUrls: ['./config-page-display.component.css']
})
export class ConfigPageDisplayComponent implements OnInit {
  public currentPage: string | null = null;
  private originalPage: string | null = null;
  public pageType: string | null = null;
  private pageSubscription: Subscription;

  processorUtilDefault: ProcessorUtilisationAlert;
  processorUtilCustom: ProcessorUtilisationAlertCustom;

  enable :boolean = true;
  selectedTab :string = 'Default'
  selectedSubTab :string = 'License';

  showSaveFeedbackMessage :boolean = false;
  showInvalidInputFeedbackMessage :boolean = false;
  showClipboardCopyMessage :boolean = false;

  selectedAlert :number = 0
  isModified :boolean = false
  isApplyToAll :boolean = false;
  serverName = null
  breadcrumb = '';
  org_crumb = "";
  licence_crumb = "";
  server_crumb = "";
  instance_crumb = ""; 
  
  server_id? = 0;
  instance_id = 0

  licence_key = "";

  showSavedMessage = false

  isRecordOverwritten :boolean = false;

  //processor utilisation alerts
  processorUtilLowSeverity : Number = 0;
  processorUtilMediumSeverity : Number = 0;
  processorUtilHighSeverity : Number = 0;
  processorUtilSeverityDuration : Number = 0;
  processorUtilSeverityLevel : Number = 0;

  processorUtilCustomExists :boolean = false;

  //server heartbeat alerts
  serverHeartbeatSeverity :Number = 0;
  serverHeartbeatSeverityDuration :Number = 0;
  serverHeartbeatAlertSeverityLevel :Number = 0;

  serverHeartbeatCustomExists :boolean = false;

  serverHeartbeatDefault: ServerHeartbeatAlert;
  serverHeartbeatCustom: ServerHeartbeatAlertCustom;

   //monitor heartbeat alerts
   monitorHeartbeatSeverity :Number = 0;
   monitorHeartbeatSeverityDuration :Number = 0;
   monitorHeartbeatAlertSeverityLevel :Number = 0;
 
   monitorHeartbeatCustomExists :boolean = false;
 
   monitorHeartbeatDefault: MonitorHeartbeatAlert;
   monitorHeartbeatCustom: MonitorHeartbeatAlertCustom;

  //Long Running Job alerts
  LongRunJobLowSeverity : Number = 0;
  LongRunJobMediumSeverity : Number = 0;
  LongRunJobHighSeverity : Number = 0;
  LongRunJobSeverityDuration : Number = 0;
  LongRunJobSeverityLevel : Number = 0;

  LongRunJobCustomExists :boolean = false;

  LongRunJobDefault: LongRunningJobAlert;
  LongRunJobCustom: LongRunningJobAlertCustom[];

   //Disk Capacity alerts
   diskCapacityLowSeverity : Number = 0;
   diskCapacityMediumSeverity : Number = 0;
   diskCapacityHighSeverity : Number = 0;
   diskCapacityIsPercentage :boolean = true;
 
   diskCapacityCustomExists :boolean = false;
 
   diskCapacityDefault: DiskCapacityAlert;
   diskCapacityCustom: DiskCapacityAlertCustom[];

   selected_server_disk_id: number = 0;
   serverDisks :getDisks[] = [
    { disk_id: 0, disk_label: 'defaultDisk' }  //Default for serverDisks, which is replaced by relevant values (indexed at 1) 
                                        //at runtime when the specific server information is loaded.
   ]

   //Job Failure alerts
   jobFailureSeverity : Number = 0;
   jobFailureAlertSeverityLevel : Number = 0;
 
   jobFailureCustomExists :boolean = false;
 
   jobFailureDefault: JobFailureAlert;
   jobFailureCustom: JobFailureAlertCustom[];

   selected_job_id: number = 0;
   instanceJobs :getJobs[] = [
    { sqlserver_agent_job_id: 0, name: ''}  //Default for job_ids, which is replaced by relevant values (indexed at 1) 
                                        //at runtime when the specific server information is loaded.
   ]

    //Full Backup alerts
    fullBackupSeverity : Number = 0;
    fullBackupSeverityDuration : Number = 0;
  
    fullBackupCustomExists :boolean = false;
    isDefault: boolean = false;
  
    fullBackupDefault: FullBackupAlert;
    fullBackupCustom: FullBackupAlertCustom[];
 
    selected_full_database_id: number = 0;
    instanceDatabases :Database[] = [
     { database_id: 0, instance_id: 0, name: ''}  //Replaced by relevant values (indexed at 1) 
                                         //at runtime when the specific server information is loaded.
    ]

    //Error log
    errorLogHighSeverityThreshold: number = 20;
    errorLogMediumSeverityThreshold: number = 17;
    errorLogLowSeverityThreshold: number = 16;
    errorLogSuppressionDuration: number = 300;

    errorLogCustomExists: boolean = false;

    errorLogDefault: ErrorLogAlert;
    errorLogCustom: ErrorLogAlertCustom[];

    //Blocking
    blockingHighSeverityDuration: number = 300;
    blockingMediumSeverityDuration: number = 180;
    blockingLowSeverityDuration: number = 30;

    blockingCustomExists: boolean = false;

    blockingDefault: BlockingAlert;
    blockingCustom: BlockingAlertCustom[];

    //Log Backup
    logBackupHighSeverityThreshold: number = 86400;
    logBackupMediumSeverityThreshold: number = 43200;
    logBackupLowSeverityThreshold: number = 21600;
    logBackupSeverityDuration :number = 0;
    logBackupCustomExists: boolean = false;

    logBackupDefault: LogBackupAlert;
    logBackupCustom: LogBackupAlertCustom[];

    selected_log_database_id: number = 0;
    instanceLogDatabases :Database[] = [
     { database_id: 0, instance_id: 0, name: ''}  //Replaced by relevant values (indexed at 1) 
                                         //at runtime when the specific server information is loaded.
    ]

    //Availability Group Health
    availabilityGroupHighSeverityValue: number = 0;
    availabilityGroupMediumSeverityValue: number = 1;
    availabilityGroupLowSeverityValue: number = 0;
    availabilityGroupSuppressionDuration: number = 300;
    
    availabilityGroupCustomExists: boolean = false;
    
    availabilityGroupDefault: AvailabilityGroupHealthAlert;
    availabilityGroupCustom: AvailabilityGroupHealthAlertCustom[];


    //agent configuration
    agent_option_interval_seconds :number;
    agent_option_is_enabled :boolean;
    
    agentConfigOptions :AgentConfigOption[] = [
      { configuration_name: '', configuration_id: 0} 
     ]
     selected_agent_config_id :number = 0;

     
    


  constructor(private configService: ConfigNavigationServiceService, public dialog :MatDialog) {}
  editions: string[] = [
    'Developer Edition (64-bit)',
    'Enterprise Edition (64-bit)',
    'Enterprise Edition: Core-based Licensing (64-bit)',
    'Enterprise Evaluation Edition (64-bit)',
    'Express Edition (64-bit)',
    'SQL Azure',
    'Standard Edition (64-bit)',
    'Web Edition (64-bit)'
  ];

  collations :string[] = [
    'Latin1_General_BIN',
    'Latin1_General_BIN2',
    'Latin1_General_CI_AS',
    'SQL_Latin1_General_CP1_CI_AI',
    'SQL_Latin1_General_CP1_CI_AS'
  ];

  serverAlerts = [
    { value: 3, label: 'Processor Utilisation' },
    { value: 8, label: 'Server Heartbeat' },
    { value: 1, label: 'Disk Capacity' }
  ];
  licenseAlerts = [
    { value: 7, label: 'Monitor Heartbeat' },
  ];

  severityOptions = [
    {value: 0, label: 'None'},
    {value: 1, label: 'Low'},
    {value: 2, label: 'Medium'},
    {value: 3, label: 'High'},
  ]

  severityAlertOptions = [
    {value: 99, label: 'None'},
    {value: 1, label: 'Low'},
    {value: 2, label: 'Medium'},
    {value: 3, label: 'High'},
  ]

 
  instanceAlerts = [
    { value: 2, label: 'Job Failure' },
    { value: 12, label: 'Long Running Job' },
    { value: 9, label: 'Overdue Full Backup' },
    { value: 11, label: 'Overdue Log Backup' },

    //Need example of configuration for the following:
    { value: 4, label: 'Error Log' },
    { value: 5, label: 'Blocking Process' },
    { value: 13, label: 'Availability Group Health' },
  ];

  allAlerts = this.serverAlerts.concat(this.licenseAlerts).concat(this.instanceAlerts);

  modificationActions = [
    {value: 1, label: 'Default'}
  ]

  loadProcessorUtilisationDefault(): void {
    ////console.log("Loading in default alerts")

    


    this.configService.getProcessorUtilisationAlert().subscribe(
      (data: ProcessorUtilisationAlert) => {
        this.processorUtilDefault = data;
        ////console.log(data)

        this.processorUtilLowSeverity = this.processorUtilDefault.lowSeverityThreshold
        this.processorUtilMediumSeverity = this.processorUtilDefault.mediumSeverityThreshold
        this.processorUtilHighSeverity = this.processorUtilDefault.highSeverityThreshold
        this.processorUtilSeverityDuration = this.processorUtilDefault.severityDuration
        this.processorUtilSeverityLevel = this.processorUtilDefault.alertSeverityLevel


      },
      error => {
        console.error('Error fetching default alert', error);
      }
    );
  }

  loadProcessorUtilisationCustom(server_id :number): void {
    ////console.log("Loading in custom alerts")
    this.configService.getProcessorUtilisationAlertCustom(server_id).subscribe(
      (data: ProcessorUtilisationAlertCustom) => {
        ////console.log("custom alert processor data")
        ////console.log(data)
        this.processorUtilCustom = data;

        if (this.processorUtilCustom.server_id == 0) //no custom found. Instead, go load defaults.
        {
          ////console.log("not found serverid. Instead, loading defaults.")
          //this.processorUtilCustomExists = false;
          this.loadProcessorUtilisationDefault()

        } else  
        { 
            this.processorUtilCustomExists = true;
            this.isModified = true

            this.processorUtilLowSeverity = this.processorUtilCustom.lowSeverityThreshold
            this.processorUtilMediumSeverity = this.processorUtilCustom.mediumSeverityThreshold
            this.processorUtilHighSeverity = this.processorUtilCustom.highSeverityThreshold
            this.processorUtilSeverityDuration = this.processorUtilCustom.severityDuration
            this.processorUtilSeverityLevel = this.processorUtilCustom.alertSeverityLevel

          }
        }
    )
    }

    loadServerHeartbeatDefault(): void {
      ////console.log("Loading in default alerts - calling heartbeat default")
  
      this.configService.GetAlertServerHeartbeat().subscribe(
        (data: ServerHeartbeatAlert) => {
          this.serverHeartbeatDefault = data;
          ////console.log("logging...!!")
          ////console.log(data)
          ////console.log(this.serverHeartbeatDefault)
   
          this.serverHeartbeatSeverity = this.serverHeartbeatDefault.severity
          this.serverHeartbeatSeverityDuration = this.serverHeartbeatDefault.severityDuration
          this.serverHeartbeatAlertSeverityLevel = this.serverHeartbeatDefault.alertSeverityLevel
  
  
        },
        error => {
          console.error('Error fetching default alert', error);
        }
      );
    }
  
    loadServerHeartbeatCustom(server_id :number): void {
      ////console.log("Loading in custom alerts")
      this.configService.GetAlertServerHeartbeatCustom(server_id).subscribe(
        (data: ServerHeartbeatAlertCustom) => {
          ////console.log("custom alert processor data")
          ////console.log(data)
          this.serverHeartbeatCustom = data;
  
          if (this.serverHeartbeatCustom.server_id == 0) //no custom found. Instead, go load defaults.
          {
            ////console.log("not found serverid. Instead, loading defaults.")
            this.loadServerHeartbeatDefault()
  
          } else  
          { 
              this.serverHeartbeatCustomExists = true;
              this.isModified = true
  
              this.serverHeartbeatSeverity = this.serverHeartbeatCustom.severity
              this.serverHeartbeatSeverityDuration = this.serverHeartbeatCustom.severityDuration
              this.serverHeartbeatAlertSeverityLevel = this.serverHeartbeatCustom.alertSeverityLevel
            }
          }
      )
      }

    resetAlerts(){
      setTimeout(() => {
        this.onResetAlert();
      }, 300);
    
     
    }  
   onResetAlert()
   {
    ////console.log('Swapping Loaded Alert:');

    this.isModified = false;

    const alertValue = Number(this.selectedAlert);
  ////console.log('alertValue:', alertValue); // Add this log to check the type and value
  switch (alertValue) {  
      case 3:
          ////console.log('case 3; ' + alertValue);
          this.loadProcessorUtilisationCustom(this.server_id);
          break;
      case 7:
        ////console.log('case 7; ' + alertValue);
        this.loadMonitorHeartbeatCustom(this.licence_key);
        break;
      case 8:
          ////console.log('case 8; ' + alertValue);
          this.loadServerHeartbeatCustom(this.server_id);
          break;
      case 12:
          ////console.log('case 12; ' + alertValue);
          this.loadLongRunningJobCustom(this.server_id, this.instance_id);
          break;
      case 1:
        ////console.log('case 1; ' + alertValue);
        this.loadDiskCapacityCustom(this.server_id);
        break;
      case 2:
        ////console.log('case 2; ' + alertValue);
        this.loadJobFailureCustom(this.server_id, this.instance_id);
        break;
      case 9:
        ////console.log('case 9; ' + alertValue);
        this.loadFullBackupCustom(this.server_id, this.instance_id);
        break;
      case 4:
        ////console.log('case 4; ' + alertValue);
        this.loadErrorLogCustom(this.server_id, this.instance_id);
        break;
    case 5:
        ////console.log('case 5; ' + alertValue);
        this.loadBlockingCustom(this.server_id, this.instance_id);
        break;
    case 11:
        ////console.log('case 11; ' + alertValue);
        this.loadLogBackupCustom(this.server_id, this.instance_id);
        break;
    case 13:
        ////console.log('case 13; ' + alertValue);
        this.loadAvailabilityGroupHealthCustom(this.server_id, this.instance_id);
        break;
  

      default:
          ////console.log('case default; ' + alertValue);
          break;
  }

   }
  ngOnInit() {
    
    this.currentPage = "page"

   
    this.pageSubscription = this.configService.pageState$.subscribe((state) => {
      if (state) {

        this.selectedTab = 'Details'

        this.showSaveFeedbackMessage = false;
        this.showInvalidInputFeedbackMessage  = false;
        this.showClipboardCopyMessage = false;
        this.showSavedMessage = false;


        this.isModified = false;
        //console.log('state', state)
        //console.log(state)
        
        this.selected_agent_config_id = 0;

        this.pageType = state.type;
        this.currentPage = state;
        this.originalPage = state;
        this.breadcrumb = '';

        this.breadcrumb += state.organization_name + " > ";
        this.org_crumb = state.organization_name;
        this.licence_crumb = "";
        this.server_crumb = "";
        this.instance_crumb = ""; 
        

       if (state.type == 'server')
          {

            if (!state.isNew)
            {
   
              //console.log("server id detected")
              this.server_id = state.server_id
              this.loadProcessorUtilisationCustom(state.server_id); 
              this.loadServerHeartbeatCustom(state.server_id); 
              this.loadAgentOptions("Wmi")
            } else{
              ////console.log("No server id detected, loading defaults")
              this.loadProcessorUtilisationDefault()
              this.loadServerHeartbeatDefault()
            }
         
          }

        if (state.type == 'license')
          { 

            if (!state.isNew)
            {

            ////console.log("license key detected")
            this.licence_key = state.name
            this.loadMonitorHeartbeatCustom(this.licence_key);  
            }
            else{
              ////console.log("No licence key detected, loading defaults")
              this.loadMonitorHeartbeatDefault() 
            }

          } 

          if (state.type == "instance")
            {
              if (!state.isNew)
              {
                this.loadAgentOptions("SqlServer")
            
                ////console.log("instance id detected")
                this.instance_id = state.sqlserver_instance_id
                this.loadJobFailureCustom(this.instance_id, state.sqlserver_instance_id, true);  
              }else{
                ////console.log("No instance id detected, loading defaults")
                this.loadJobFailureDefault() 
              }
             
            } 
       

        if (this.pageType == 'license')
          { 

            if (state.description != null && state.description != "")
              {
                this.breadcrumb += state.description;   
                this.licence_crumb = state.description;     
              } else
              {
                this.breadcrumb += state.name;    
                this.licence_key = state.name;    
              }
                 
          }

          
        if (this.pageType == "server")
          { 

            this.breadcrumb += state.license_name;   
            this.licence_crumb = state.description;  

            if (state.description != null && state.description != "")
              {
                this.breadcrumb += ' > ' + state.description;     
                this.server_crumb = state.description   
              } else
              {
                if (state.name != '')
                  {
                    this.breadcrumb += ' > ' + state.name; 
                    this.server_crumb = state.name      
                  }
                 
              }          
          }
          if (this.pageType == "instance")
            { 
              this.breadcrumb += state.license_name + " > " + state.serverName + " > "
              this.licence_crumb = state.licence_name;
              this.server_crumb = state.serverName;

              if (state.name != null && state.name != '')
                {
                  this.breadcrumb += state.name; 
                  this.instance_crumb = state.name;        
                } else
                {
                  this.breadcrumb += state.sqlserver_instance_id;         
                  this.instance_crumb = state.name;
                }
                 
            }
            if (this.pageType == 'organisation')
              { 
                this.breadcrumb = state.name;  
                this.org_crumb = state.name;      
                     
              }

              //console.log('breadcrumb is ' + this.breadcrumb)
              if (this.breadcrumb.includes('undef') || this.breadcrumb == '')
                {
                  this.breadcrumb = 'New Organization'
                }
  
        

        ////console.log('state is ', state)

        if (state.type == 'server' && state.name != "")
          {
            this.resetAlerts();

            this.serverName = state.name;  
            this.server_id = state.server_id;
            
           
            ////console.log('serverid new one is ' + this.server_id)
          }

          if (state.type == 'license' && state.name != "")
            {
              this.resetAlerts();
  
              this.serverName = state.name;  
              this.server_id = state.server_id;
              
             
              ////console.log('serverid new one is ' + this.server_id)
            }

            if (state.type == 'instance' && state.name != "")
              {
                console.log('instance state 121', state);
                this.serverName = state.name;  
                this.server_id = state.server_id;
                this.instance_id = state.sqlserver_instance_id


                this.resetAlerts();
    
               
                
               
                ////console.log('instanceid new one is ' + this.instance_id)
              }

        if (this.pageType == 'license' && !this.licenseAlerts.some(alert => alert.value == this.selectedAlert)) {
          ////console.log("Found that selected alert was not a license. Moving to first license.");
          this.selectedAlert = this.licenseAlerts[0].value; 
        }

        
        if (this.pageType == 'server' && !this.serverAlerts.some(alert => alert.value == this.selectedAlert)) {
          ////console.log("Found that selected alert was not a server. Moving to first server.");
          this.selectedAlert = this.serverAlerts[0].value; 
        }

        if (this.pageType == 'instance' && !this.instanceAlerts.some(alert => alert.value == this.selectedAlert)) {
          ////console.log("Found that selected alert was not an instance. Moving to first instance.");
          this.selectedAlert = this.instanceAlerts[0].value; 
        }
      }

      
      if (this.selectedTab == 'Default' && this.selectedSubTab == 'License')
        { //load the default page.

          this.selectedAlert = this.licenseAlerts[0].value; 
          this.onAlertListBoxChangeDefault()
        }
    });


   

    this.configService.tabSelected$.subscribe(
      selection => {
        const { tabName, subtab } = selection;
        this.SelectTab({ tab: tabName, subtab });
      }
    );
    
    this.configService.getreload$().pipe(
      switchMap(() => this.configService.getOrganisationData())
    ).subscribe(data => {
      ////console.log('reloading');
      //this.pageType = 'none'
    });
 
  }

 
  
  SelectTab(arg1: { tab: string, subtab: string } | string, arg2?: string) {
  {
    let tab: string;
    let subtab: string;

    this.showSaveFeedbackMessage = false;
    this.showInvalidInputFeedbackMessage = false;
    this.showSavedMessage = false;
    this.showClipboardCopyMessage = false;

  
    if (typeof arg1 === 'string' && arg2 !== undefined) {
      // Handle case where arguments are (tab, subtab)
      tab = arg1;
      subtab = arg2;
    
    } else if (typeof arg1 === 'object' && 'tab' in arg1 && 'subtab' in arg1) {
      // Handle case where argument is ({ tab, subtab })
      tab = arg1.tab;
      subtab = arg1.subtab;

      if (this.selectedTab != tab)
        {
          this.server_id = 0;
          this.instance_id = 0;
        }
    }

 

    this.selectedTab = tab;
    this.selectedSubTab = subtab;

    if (this.selectedTab == 'Default')
      {
        this.pageType = '';
        this.breadcrumb = ''; 
      }

    this.selectedAlert = 0;
    ////console.log("SELECTED TAB IS CURRENTLY " + this.selectedTab + " \\ " + this.selectedSubTab)

    if (this.selectedTab == 'Default')
      {
        if (this.selectedSubTab == 'License' && !this.licenseAlerts.some(alert => alert.value == this.selectedAlert)) {
          ////console.log("Found that selected alert was not a license. Moving to first license.");
          this.selectedAlert = this.licenseAlerts[0].value; 
          this.onAlertListBoxChangeDefault()
        }
    
        
        if (this.selectedSubTab == 'Server' && !this.serverAlerts.some(alert => alert.value == this.selectedAlert)) {
          ////console.log("Found that selected alert was not a server. Moving to first server.");
          this.selectedAlert = this.serverAlerts[0].value; 
          this.onAlertListBoxChangeDefault()
        }
    
        if (this.selectedSubTab == 'Instance' && !this.instanceAlerts.some(alert => alert.value == this.selectedAlert)) {
          ////console.log("Found that selected alert was not an instance. Moving to first instance.");
          this.selectedAlert = this.instanceAlerts[0].value;
          this.onAlertListBoxChangeDefault() 
        }
      }

      if (this.selectedTab != 'Default' &&  this.pageType != '' && this.pageType != null)
        {
          if (this.pageType == 'license' && !this.licenseAlerts.some(alert => alert.value == this.selectedAlert)) {
            ////console.log("Found that selected alert was not a license. Moving to first license.");
            this.selectedAlert = this.licenseAlerts[0].value; 
            this.onAlertListBoxChange()
          }
      
          
          if (this.pageType == 'server' && !this.serverAlerts.some(alert => alert.value == this.selectedAlert)) {
            ////console.log("Found that selected alert was not a server. Moving to first server.");
            this.selectedAlert = this.serverAlerts[0].value; 
            this.onAlertListBoxChange()
          }
      
          if (this.pageType == 'instance' && !this.instanceAlerts.some(alert => alert.value == this.selectedAlert)) {
            ////console.log("Found that selected alert was not an instance. Moving to first instance.");
            this.selectedAlert = this.instanceAlerts[0].value;
            this.onAlertListBoxChange() 
          }
        }
   
    
  }
}







reload()
{
  setTimeout(() => {
    const now = new Date();
    const oneSecondFuture= new Date(now.getTime() + 1000);
  
    if (!(this.configService.lastReload  > oneSecondFuture)) //if auto reload will hit in less than 1 second, don't auto reload.
    {
      this.configService.triggerReload();
    }
  }, 200);
}

copyLicenseKeyToClipboard() {
  const textToCopy = this.licence_key; // Specify the text you want to copy

  // Create a hidden textarea element
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;

  // Avoid scrolling to bottom
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = '0';
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';

  document.body.appendChild(textArea);
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    //console.log(`Fallback: Copying text command was ${msg}`);
    if (successful) {
      this.ShowFeedbackMessage(2);
    }
  } catch (err) {
    console.error('Unable to copy', err);
  }

  // Remove the textarea
  document.body.removeChild(textArea);
}

saveServer(name, org_id, licence_key, is_active, server_id)
  {
      this.configService.saveServer(name, org_id, licence_key, is_active, server_id)


      setTimeout(() => {    //Show saved license message for 3 seconds.
        this.showSavedMessage = false;
      }, 5000);
      this.reload()

      this.ShowFeedbackMessage(3)

  }

  deleteServer(name: string, org_id: string, licence_key: string, is_active: boolean, server_id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      data: { name: name, type: "server" }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.configService.deleteServer(name, org_id, licence_key, is_active, server_id);
        this.revertChanges();
      }
    });
  }
  
   
  saveInstance(server_id, name,sqlserver_instance_id,  port)
  {    
    this.configService.saveInstance(server_id, sqlserver_instance_id, name, port)

    setTimeout(() => {    //Show saved license message for 3 seconds.
      this.showSavedMessage = false;
    }, 5000);

    if (sqlserver_instance_id == 0)
      {
        this.revertChanges();
      }
    
    this.reload()

    this.ShowFeedbackMessage(3)

  } 

  

  saveLicense(license_key, description, is_active, organisation_id)
  { 

    this.configService.saveLicense(license_key, description, is_active, organisation_id)
    
    this.reload()

    this.ShowFeedbackMessage(3)

    
  } 

  saveOrganization(id, btNet, name)
  { 

    //console.log('id: ' + id);
    //console.log('name: ' + name);
    //console.log('btNet: ' + btNet);

    if (id <= 0 || id == null)
      {
        id = 0;
      }

    this.configService.saveOrganization(id, btNet, name)
    
    this.reload()

    this.ShowFeedbackMessage(3)

    
  } 
  deleteOrganization(id: string, name :string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      data: { name: name, type: 'organization' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.configService.deleteOrganization(id);
        this.revertChanges();
      }
    });
  }

  deleteInstance(instance_info_id: string, name: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      data: { name: name, type: 'instance' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.configService.deleteInstance(instance_info_id);
        this.revertChanges();
      }
    });
  }

  deleteLicense(license_key: string, name:string) {

    if (name.length == 0)
    {
      name = license_key;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      data: { name: name, type: 'license' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.configService.deleteLicense(license_key);
        this.revertChanges();
      }
    });
  }

  revertChanges()
  {    
    this.reload()
    this.pageType = this.originalPage

    this.showSaveFeedbackMessage = false;
    this.showInvalidInputFeedbackMessage = false;
    this.showSavedMessage = false;
  }

  cancelChanges(){
    //this.pageType = this.originalPage
    this.reload()
    this.resetAlerts();
    
    if (this.selectedTab == 'Alert')
      {
        this.onAlertListBoxChangeDefault();
      } else
      {
        this.onAlertListBoxChange();
      }

    this.showSaveFeedbackMessage = false;
    this.showInvalidInputFeedbackMessage = false;
    this.showSavedMessage = false;
  }

  ngOnDestroy() {
    // Unsubscribe to prevent client-side memory leaks on leaving page
    if (this.pageSubscription) {
      this.pageSubscription.unsubscribe();
    }
  }

  onAlertListBoxChangeDefault() :void
  {
    this.isModified = false;
    this.showSaveFeedbackMessage = false;
    this.showInvalidInputFeedbackMessage = false;


    const alertValue = Number(this.selectedAlert);
    ////console.log('alertValue:', alertValue); // Add this log to check the type and value
    switch (alertValue) {  
        case 3:
            ////console.log('case 3; ' + alertValue);
            this.loadProcessorUtilisationDefault();
            break;
        case 7:
          ////console.log('case 7; ' + alertValue);
          this.loadMonitorHeartbeatDefault();
          break;
        case 8:
            ////console.log('case 8; ' + alertValue);
            this.loadServerHeartbeatDefault();
            break;
        case 12:
            ////console.log('case 12; ' + alertValue);
            this.loadLongRunningJobDefault();
            break;
        case 1:
          ////console.log('case 1; ' + alertValue);
          this.loadDiskCapacityDefault();
          break;
        case 2:
          ////console.log('case 2; ' + alertValue);
          this.loadJobFailureDefault();
          break;
        case 9:
          ////console.log('case 2; ' + alertValue);
          this.loadFullBackupDefault();
          break;
        case 4:
            ////console.log('case 4; ' + alertValue);
            this.loadErrorLogDefault();
            break;
        case 5:
            ////console.log('case 5; ' + alertValue);
            this.loadBlockingDefault();
            break;
        case 11:
            ////console.log('case 11; ' + alertValue);
            this.loadLogBackupDefault();
            break;
        case 13:
            ////console.log('case 13; ' + alertValue);
            this.loadAvailabilityGroupHealthDefault();
            break;
    
  
        default:
            ////console.log('case default; ' + alertValue);
            break;
    }    
  }


  onAgentOptionChange() :void
  {
    //console.log('entering query for: server: ' + this.server_id  + " instance: " + this.instance_id + " and config: " + this.selected_agent_config_id)

    let instance = this.instance_id;

    if (instance == null)
      {
        instance = 0;
      }
    this.configService.GetAgentConfiguration(this.server_id, instance, this.selected_agent_config_id).subscribe(
      (data: AgentConfig) => {
       
        if (data != null)
          {
            //console.log('agent config option: ',data)
            this.agent_option_interval_seconds = data.interval_seconds;
            this.agent_option_is_enabled = data.is_enabled;
          } else
          {
            //console.log('data was null');
          }
      
      });

  }

  saveAgentOption() :void
  {
    
    let instance = this.instance_id;

    if (instance == null)
      {
        instance = 0;
      }

    //console.log('saving agent', { server_id :this.server_id, instance_id :Number(instance), configuration_id:Number(this.selected_agent_config_id), interval_seconds:this.agent_option_interval_seconds, is_enabled:this.agent_option_is_enabled})
    this.configService.SaveAgentConfiguration(this.server_id, Number(instance), Number(this.selected_agent_config_id), this.agent_option_interval_seconds, this.agent_option_is_enabled).subscribe();
    this.reloadAgentOptions();
    this.ShowFeedbackMessage(3)
  }

  deleteAgentOption() :void
  {
    
    let instance = this.instance_id;

    if (instance == null)
      {
        instance = 0;
      }

    //console.log('deleting agent', { server_id :this.server_id, instance_id :Number(instance), configuration_id:Number(this.selected_agent_config_id)})
    this.configService.DeleteAgentConfiguration(this.server_id, Number(instance), Number(this.selected_agent_config_id)).subscribe();
    this.reloadAgentOptions();
  }

  reloadAgentOptions() :void
  {
    setTimeout(() => {
      this.onAgentOptionChange();
    }, 300);
    
  }

  onAlertListBoxChange(): void {
    ////console.log('Swapping Loaded Alert:');

    this.isModified = false;
    this.showSaveFeedbackMessage = false;
    this.showInvalidInputFeedbackMessage = false;

    const alertValue = Number(this.selectedAlert);
  ////console.log('alertValue:', alertValue); // Add this log to check the type and value
  switch (alertValue) {  
      case 3:
          ////console.log('case 3; ' + alertValue);
          this.loadProcessorUtilisationCustom(this.server_id);
          break;
      case 7:
        ////console.log('case 7; ' + alertValue);
        this.loadMonitorHeartbeatCustom(this.licence_key);
        break;
      case 8:
          ////console.log('case 8; ' + alertValue);
          this.loadServerHeartbeatCustom(this.server_id);
          break;
      case 12:
          ////console.log('case 12; ' + alertValue);
          this.loadLongRunningJobCustom(this.server_id, this.instance_id, true);
          this.selected_job_id = 0;
          break;
      case 1:
        ////console.log('case 1; ' + alertValue);
        this.loadDiskCapacityCustom(this.server_id, true);
        this.selected_server_disk_id = 0;
        break;
      case 2:
        ////console.log('case 2; ' + alertValue);
        this.loadJobFailureCustom(this.server_id, this.instance_id, true);
        this.selected_job_id = 0;
        break;
      case 9:
        ////console.log('case 9; ' + alertValue);
        this.loadFullBackupCustom(this.server_id, this.instance_id, true);
        this.selected_full_database_id = 0;
        break;
      case 4:
          ////console.log('case 4; ' + alertValue);
          this.loadErrorLogCustom(this.server_id, this.instance_id);
      
          break;
      case 5:
          ////console.log('case 5; ' + alertValue);
          this.loadBlockingCustom(this.server_id, this.instance_id);
          break;
      case 11:
          ////console.log('case 11; ' + alertValue);
          this.loadLogBackupCustom(this.server_id, this.instance_id);
          this.selected_log_database_id = 0;
          break;
      case 13:
          ////console.log('case 13; ' + alertValue);
          this.loadAvailabilityGroupHealthCustom(this.server_id, this.instance_id);
          break;
      default:
          ////console.log('case default; ' + alertValue);
          break;
  }

   
}
  onModificationChange(): void {
    ////console.log('Selected Modification:', this.isModified);
    
    if (this.selectedAlert == 3)
      {
        if (this.isModified == true) //custom
        {
          this.processorUtilCustomExists = true
          this.loadProcessorUtilisationCustom(this.server_id)
        } else
        {
          this.loadProcessorUtilisationDefault()
        }
      }

      if (this.selectedAlert == 8)
        {
          if (this.isModified == true) //custom
          {
            this.serverHeartbeatCustomExists = true
            this.loadServerHeartbeatCustom(this.server_id)
            this.isModified = true
          } else
          {
            this.loadServerHeartbeatDefault()
          }
        }
        if (this.selectedAlert == 12)
          {
            if (this.isModified == true) //custom
            {
              this.LongRunJobCustomExists = true
              this.loadLongRunningJobCustom(this.server_id, this.instance_id)
            } else
            {
              this.loadLongRunningJobDefault()
            }
          }
          if (this.selectedAlert == 1)
            {
              if (this.isModified == true) //custom
              {
                this.diskCapacityCustomExists = true
                this.loadDiskCapacityCustom(this.server_id)
              } else
              {
                this.loadDiskCapacityDefault()
              }
          }
          if (this.selectedAlert == 2)
            {
              if (this.isModified == true) //custom
              {
                this.jobFailureCustomExists = true
                this.loadJobFailureCustom(this.server_id, this.instance_id)
              } else{
                this.loadJobFailureDefault()
              }
          }
          if (this.selectedAlert == 7)
            {
              ////console.log('calling from the select alert no case')
              if (this.isModified == true) //custom
              {    
                this.monitorHeartbeatCustomExists = true
                this.loadMonitorHeartbeatCustom(this.licence_key)
            
              } else
              {
                this.loadMonitorHeartbeatDefault()
              }
          }

          if (this.selectedAlert == 9)
            { 
              if (this.isModified == true) //custom
              {    
                this.fullBackupCustomExists = true
                this.loadFullBackupCustom(this.server_id, this.instance_id)
            
              } else
              {
                this.loadFullBackupDefault()
              }
          }

          
          if (this.selectedAlert == 4)
            { 
              if (this.isModified == true) //custom
              {    
                this.fullBackupCustomExists = true
                this.loadErrorLogCustom(this.server_id, this.instance_id)
            
              } else
              {
                this.loadErrorLogDefault()
              }
          }

          
          if (this.selectedAlert == 5)
            { 
              if (this.isModified == true) //custom
              {    
                this.fullBackupCustomExists = true
                this.loadBlockingCustom(this.server_id, this.instance_id)
            
              } else
              {
                this.loadBlockingDefault()
              }
          }

          
          if (this.selectedAlert == 11)
            { 
              if (this.isModified == true) //custom
              {    
                this.fullBackupCustomExists = true
                this.loadLogBackupCustom(this.server_id, this.instance_id)
            
              } else
              {
                this.loadLogBackupDefault()
              }
          }

          
          if (this.selectedAlert == 13)
            { 
              if (this.isModified == true) //custom
              {    
                this.fullBackupCustomExists = true
                this.loadAvailabilityGroupHealthCustom(this.server_id, this.instance_id)
            
              } else
              {
                this.loadAvailabilityGroupHealthDefault()
              }
          }
  }

deleteModification()
{
  ////console.log("DELETING MOD")
  if (this.selectedAlert == 3)
    {
      if (this.isModified == false) //default
      {
        this.deleteProcessorUtilisationCustom();
      } 
    }

    if (this.selectedAlert == 8)
      {

        if (this.isModified == false) //default
        {
          this.deleteServerHeartbeatCustom()
        } 
      }

      if (this.selectedAlert == 12)
        {
          if (this.isModified == false) //default
          {
            this.deleteLongRunningJobCustom()
          } 
        }

        if (this.selectedAlert == 1)
          {
            if (this.isModified == false) //default
            {
              this.deleteDiskCapacityCustom()
            } 
        }

        if (this.selectedAlert == 2)
          {
            if (this.isModified == false) //default
            {
              this.deleteJobFailureCustom()
            } 
        }

        if (this.selectedAlert == 7)
          {
            if (this.isModified == false) //default
            {
              this.deleteMonitorHeartbeatCustom()
            }
        }

        if (this.selectedAlert == 9)
          {
            if (this.isModified == false) //default
            {
              this.deleteFullBackupCustom()
            }
        }
      if (this.selectedAlert == 2) 
        {
          if (this.isModified == false) 
            { // default
              this.deleteJobFailureCustom();
          }
        }
      if (this.selectedAlert == 4) 
        {
          if (this.isModified == false) 
            { // default
              this.deleteErrorLogCustom();
          }
        }
       if (this.selectedAlert == 5) 
        {
          if (this.isModified == false) 
            { // default
              this.deleteBlockingCustom();
          }
      } 
      if (this.selectedAlert == 11) 
        {
          if (this.isModified == false) 
            { // default
              this.deleteLogBackupCustom();
          }
      } 
      if (this.selectedAlert == 13) 
        {
          if (this.isModified == false) 
            { // default
              this.deleteAvailabilityGroupHealthCustom();
          }
      }
      

}
//agent configuration
loadAgentOptions(type :string)
{
  this.configService.GetAgentConfigurationOptions(type).subscribe(
    (data: AgentConfigOption[]) => {
      //console.log('data of agent options: ', data)
      this.agentConfigOptions = data;

      if (this.selected_agent_config_id == 0)
        {
          this.selected_agent_config_id = this.agentConfigOptions[0].configuration_id
          this.onAgentOptionChange();
        }
    });
}






  //alerts

  saveProcessorUtilisationCustom()
  {   //alert id 3
    const body = {
      Objects: {server_id: this.server_id},
      Configuration: {"HighSeverityThreshold": this.processorUtilHighSeverity, "MediumSeverityThreshold": this.processorUtilMediumSeverity, "LowSeverityThreshold": this.processorUtilLowSeverity, "SeverityDuration": this.processorUtilSeverityDuration, "AlertSeverityLevel": this.processorUtilSeverityLevel}
    };

    ////console.log(body)
    if (this.processorUtilHighSeverity < this.processorUtilMediumSeverity || this.processorUtilMediumSeverity < this.processorUtilLowSeverity )
      {
        this.ShowFeedbackMessage(0);
        return;    
      } else
      {
        this.ShowFeedbackMessage(1)
    
        this.saveAlertToDB(JSON.stringify(body), 3)
      }
      
   

    setTimeout(() => {    //give query time to finish on that side.
      this.resetAlerts();
    }, 200);

      
  }

  saveServerHeartbeatCustom()
  {
    const body = {
      Objects: {server_id: this.server_id},
      Configuration: {"Severity": this.serverHeartbeatSeverity, "SeverityDuration": this.serverHeartbeatSeverityDuration, "AlertSeverityLevel": this.serverHeartbeatAlertSeverityLevel}
    };

    ////console.log(body)
    this.saveAlertToDB(JSON.stringify(body), 8)

    this.ShowFeedbackMessage(1)

    setTimeout(() => {    //give query time to finish on that side.
      this.resetAlerts();
    }, 200);



      
  }

  saveProcessorUtilisationDefault()
  {
    const body = {
      Configuration: {"HighSeverityThreshold": this.processorUtilHighSeverity, "MediumSeverityThreshold": this.processorUtilMediumSeverity, "LowSeverityThreshold": this.processorUtilLowSeverity, "SeverityDuration": this.processorUtilSeverityDuration, "AlertSeverityLevel": this.processorUtilSeverityLevel}
    };

    ////console.log(body)
 

   
    if (this.processorUtilHighSeverity < this.processorUtilMediumSeverity || this.processorUtilMediumSeverity < this.processorUtilLowSeverity )
      {
        this.ShowFeedbackMessage(0);
        return;    
      } else
      {
        this.ShowFeedbackMessage(1)
        this.saveAlertToDB(JSON.stringify(body), 3)
      }
      
   

    setTimeout(() => {    //give query time to finish on that side.
      this.resetAlerts();
    }, 400);

      
  }

  saveServerHeartbeatDefault()
  {
    const body = {
      Configuration: {"Severity": this.serverHeartbeatSeverity, "SeverityDuration": this.serverHeartbeatSeverityDuration, "AlertSeverityLevel": this.serverHeartbeatAlertSeverityLevel}
    };

    ////console.log(body)
    this.saveAlertToDB(JSON.stringify(body), 8)

   this.ShowFeedbackMessage(1);
    
   

    setTimeout(() => {    //give query time to finish on that side.
      this.resetAlerts();
    }, 400);
      
   
  }

  deleteProcessorUtilisationCustom()
  {
    const body = {
      Objects: {server_id: this.server_id},
      Configuration: {"HighSeverityThreshold": this.processorUtilHighSeverity, "MediumSeverityThreshold": this.processorUtilMediumSeverity, "LowSeverityThreshold": this.processorUtilLowSeverity, "SeverityDuration": this.processorUtilSeverityDuration, "AlertSeverityLevel": this.processorUtilSeverityLevel}
    };

    ////console.log(body)
    this.deleteAlertFromDB(JSON.stringify(body), 3)

    this.ShowFeedbackMessage(1);
    

    setTimeout(() => {    //give query time to finish on that side.
      this.resetAlerts();
    }, 200);

 
  }

  deleteServerHeartbeatCustom()
  {
    const body = {
      Objects: {server_id: this.server_id},
      Configuration: {"Severity": this.serverHeartbeatSeverity, "SeverityDuration": this.serverHeartbeatSeverityDuration, "AlertSeverityLevel": this.serverHeartbeatAlertSeverityLevel}
    };

    ////console.log(body)
    this.deleteAlertFromDB(JSON.stringify(body), 8)

    this.ShowFeedbackMessage(1);
    

    setTimeout(() => {    //give query time to finish on that side.
      this.resetAlerts();
    }, 200);
 
  }

  ShowFeedbackMessage(status :number)
  {
    //status of 3 = successful save on details
    //status of 2 = successful clipboard copy
    //status of 1 = successful save on alert
    //status of 0 = failed save.

    if (status == 3)
      {

        this.showInvalidInputFeedbackMessage = false;
        this.showSaveFeedbackMessage = false;
        this.showClipboardCopyMessage = false;

        this.showSavedMessage = true;
    
        setTimeout(() => {    //Show saved license message for 3 seconds.
          this.showSavedMessage = false;
        }, 5000);
      }
   
    if (status == 2)
      {
        this.showInvalidInputFeedbackMessage = false;
        this.showSaveFeedbackMessage = false;
        this.showSavedMessage = false;
        if (!this.showClipboardCopyMessage)
          {
            this.showClipboardCopyMessage = true
            setTimeout(() => {
              this.showClipboardCopyMessage = false;

            }, 5000);
          }
      }
  

    if (status == 1)
      {
        this.showInvalidInputFeedbackMessage = false;
        this.showClipboardCopyMessage = false;
        this.showSavedMessage = false;
        if (!this.showSaveFeedbackMessage)
          {
            this.showSaveFeedbackMessage = true
            setTimeout(() => {
              this.showSaveFeedbackMessage = false;
            }, 5000);
          }
      } else
      {
        if (status == 0)
          {
            this.showSaveFeedbackMessage = false;
            this.showClipboardCopyMessage = false;
            this.showSavedMessage = false;
            if (!this.showInvalidInputFeedbackMessage)
              {
                this.showInvalidInputFeedbackMessage = true
                setTimeout(() => {
                  this.showInvalidInputFeedbackMessage = false;
                }, 5000);
              }
          }

        
      }
  
  }

  saveAlertToDB(configJSON :string, alert_type_id :number)
  {
    console.log('configJSON = ', configJSON)
    if (this.isModified || this.selectedTab == "Default")
    {
      this.configService.saveAlertToDB(configJSON, alert_type_id)
    }
    else
    {
      this.deleteModification();
    }
  
   
     
  }

  searchForConfig(configJSON: string, alert_type_id: number, callback: (result: boolean) => void): void {
    ////console.log('searching for config');
    this.configService.SearchForJSON(configJSON, alert_type_id).subscribe((result: boolean) => {
      ////console.log("Result:", result);
      callback(result);
    });
  }
  deleteAlertFromDB(configJSON :string, alert_type_id :number)
  {
    this.configService.deleteAlertFromDB(configJSON, alert_type_id)
  }

  deleteSearchFromDB(configJSON :string, alert_type_id :number)
  {
    this.configService.deleteSearchFromDB(configJSON, alert_type_id)
  }


  saveLongRunningJobCustom(): void {
    const alertType = 12;
  
    if (this.selected_job_id == 99) {
      this.instanceJobs.pop();
  
      if (this.isApplyToAll) {
        this.instanceJobs.forEach(job => {
          const body = {
            Objects: { server_id: this.server_id, instance_id: Number(this.instance_id), job_id: Number(job.sqlserver_agent_job_id) },
            Configuration: {
              HighSeverityThreshold: this.LongRunJobHighSeverity,
              MediumSeverityThreshold: this.LongRunJobMediumSeverity,
              LowSeverityThreshold: this.LongRunJobLowSeverity,
              MinimumDuration: this.LongRunJobSeverityDuration,
              AlertSeverityLevel: this.LongRunJobSeverityLevel
            }
          };
          this.saveAlertToDB(JSON.stringify(body), alertType);
        });
      } 
        const bodyAllJobs = {
          Objects: { server_id: this.server_id, instance_id: Number(this.instance_id)},
          Configuration: {
            HighSeverityThreshold: this.LongRunJobHighSeverity,
            MediumSeverityThreshold: this.LongRunJobMediumSeverity,
            LowSeverityThreshold: this.LongRunJobLowSeverity,
            MinimumDuration: this.LongRunJobSeverityDuration,
            AlertSeverityLevel: this.LongRunJobSeverityLevel
          }
        };
        this.saveAlertToDB(JSON.stringify(bodyAllJobs), alertType);
     
  
     
    } else {
      const body = {
        Objects: { server_id: this.server_id,instance_id: Number(this.instance_id), job_id: Number(this.selected_job_id) },
        Configuration: {
          HighSeverityThreshold: this.LongRunJobHighSeverity,
          MediumSeverityThreshold: this.LongRunJobMediumSeverity,
          LowSeverityThreshold: this.LongRunJobLowSeverity,
          MinimumDuration: this.LongRunJobSeverityDuration,
          AlertSeverityLevel: this.LongRunJobSeverityLevel
        }
      };
      this.saveAlertToDB(JSON.stringify(body), alertType);
    }
  
    this.ShowFeedbackMessage(1);
  
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }

  saveLongRunningJobDefault(): void {
    const alertType = 12;
    const body = {
      Configuration: {
        HighSeverityThreshold: this.LongRunJobHighSeverity,
        MediumSeverityThreshold: this.LongRunJobMediumSeverity,
        LowSeverityThreshold: this.LongRunJobLowSeverity,
        MinimumDuration: this.LongRunJobSeverityDuration,
        AlertSeverityLevel: this.LongRunJobSeverityLevel
      }
    };
  
    this.saveAlertToDB(JSON.stringify(body), alertType);
  
    this.ShowFeedbackMessage(1);
  
    setTimeout(() => {
      this.resetAlerts();
    }, 400);
  }
  onLongRunningJobChange(): void {
    this.isModified = false;
    this.loadLongRunningJobCustom(this.server_id, this.instance_id);
  }

  deleteLongRunningJobCustom(): void {
    const alertType = 12;
  
    if (this.selected_job_id == 99) {
      const bodyAllJobs = {
        Objects: { server_id: this.server_id, instance_id:this.instance_id },
        Configuration: {
          HighSeverityThreshold: this.LongRunJobHighSeverity,
          MediumSeverityThreshold: this.LongRunJobMediumSeverity,
          LowSeverityThreshold: this.LongRunJobLowSeverity,
          MinimumDuration: this.LongRunJobSeverityDuration,
          AlertSeverityLevel: this.LongRunJobSeverityLevel
        }
      };
      this.deleteAlertFromDB(JSON.stringify(bodyAllJobs), alertType);
    } else {
      const body = {
        Objects: { server_id: this.server_id, instance_id: this.instance_id, job_id: Number(this.selected_job_id) },
        Configuration: {
          HighSeverityThreshold: this.LongRunJobHighSeverity,
          MediumSeverityThreshold: this.LongRunJobMediumSeverity,
          LowSeverityThreshold: this.LongRunJobLowSeverity,
          MinimumDuration: this.LongRunJobSeverityDuration,
          AlertSeverityLevel: this.LongRunJobSeverityLevel
        }
      };
      this.deleteAlertFromDB(JSON.stringify(body), alertType);
    }
  
    this.ShowFeedbackMessage(1);
  
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }
  loadLongRunningJobDefault(): void {
    ////console.log("Loading in default alerts")

    console.log("In long running job. Check serverID: " + this.server_id)
    this.configService.GetDefaultAllRecordLongRunningJob(12, this.server_id, this.instance_id).subscribe(
      (data: LongRunningJobAlertCustom) => {
        //console.log(data);
        ////console.log("SERVER ID!: IN DEFAULT: " + data.server_id);
        if (data == null || data.server_id == 0) {
          ////console.log("Pushing job defaults");
          this.configService.getLongRunningJobAlert().subscribe(
            (data: LongRunningJobAlert) => {
              this.LongRunJobDefault = data;
              ////console.log(data);
  
              this.LongRunJobLowSeverity = this.LongRunJobDefault.lowSeverityThreshold
              this.LongRunJobMediumSeverity = this.LongRunJobDefault.mediumSeverityThreshold
              this.LongRunJobHighSeverity = this.LongRunJobDefault.highSeverityThreshold
              this.LongRunJobSeverityDuration = this.LongRunJobDefault.minimumDuration
              this.LongRunJobSeverityLevel = this.LongRunJobDefault.alertSeverityLevel
            },
            error => {
              console.error('Error fetching default alert', error);
            }
          );
        } else {
          ////console.log("Pushing job server specific defaults");
          this.LongRunJobDefault = data;
          ////console.log(data);
  
        
          this.LongRunJobLowSeverity = this.LongRunJobDefault.lowSeverityThreshold
          this.LongRunJobMediumSeverity = this.LongRunJobDefault.mediumSeverityThreshold
          this.LongRunJobHighSeverity = this.LongRunJobDefault.highSeverityThreshold
          this.LongRunJobSeverityDuration = this.LongRunJobDefault.minimumDuration
          this.LongRunJobSeverityLevel = this.LongRunJobDefault.alertSeverityLevel
        }
      }
    );
  }


  refreshServerID()
  {
    if (this.pageType == "organisation")
    {
      
    }
  }

  loadLongRunningJobCustom(server_id: number, instance_id: number, sendToAllJob: boolean = false): void {
    const oldJob = this.selected_job_id;
    this.loadJobs();
    this.selected_job_id = oldJob;

    this.refreshServerID();
  
    this.configService.getLongRunningJobAlertCustom(server_id, instance_id).subscribe(
      (data: LongRunningJobAlertCustom[]) => {
        this.LongRunJobCustom = data;
        let foundItem = false;
        const body = {Objects:{server_id: this.server_id, instance_id: this.instance_id}};

        //console.log(data)
  
        this.configService.SearchForJSON(JSON.stringify(body), 12).subscribe((result: boolean) => {
          this.isRecordOverwritten = result;
          //console.log(data)
          if (sendToAllJob && this.isRecordOverwritten) {
            this.selected_job_id = 99;
          }

          if (this.isRecordOverwritten && this.selected_job_id == 99) {
            foundItem = true;
            this.configService.GetDefaultAllRecordLongRunningJob(12, this.server_id, this.instance_id).subscribe(
              response => {
                this.loadLongRunningJobHelper(response);
              }
            );
          } else {
            //console.log(data)
            for (let i = 0; i < data.length; i++) {
              //console.log('job: ' + (data[i].job_id) + " " + this.selected_job_id + " instance: " + data[i].instance_id + " " + this.instance_id + " server: " + data[i].server_id + " " + this.server_id);
              if (this.selected_job_id == (data[i].job_id) && this.instance_id == data[i].instance_id && this.server_id == data[i].server_id) {
                foundItem = true;
                ////console.log('found the job');
                this.loadLongRunningJobHelper(data[i]);
              }
            }
          }
  
          if (!foundItem) {
            this.loadLongRunningJobDefault();
          }
        });
      }
    );
  }
  
  loadLongRunningJobHelper(alert :LongRunningJobAlertCustom )
  {
    this.LongRunJobCustomExists = true;
    this.isModified = true;
  
    this.LongRunJobHighSeverity = alert.highSeverityThreshold;
    this.LongRunJobMediumSeverity = alert.mediumSeverityThreshold;
    this.LongRunJobLowSeverity = alert.lowSeverityThreshold;
    this.LongRunJobSeverityDuration = alert.minimumDuration;
    this.LongRunJobSeverityLevel = alert.alertSeverityLevel;
    
  }

    //Disk capacity
  saveDiskCapacityCustom()
  {

    const alertType = 1

   

    if (this.selected_server_disk_id == 99) //this is the "All Disks" option.
    {
      //loop for each item in serverDisks
      this.serverDisks.pop() //this will remove the "AllDisks" option from the array, as it is the last item in the array.

      if (this.isApplyToAll)
        {
          this.serverDisks.forEach(disk => {
            ////console.log("doing item ", disk.disk_id)     
            const body = {
              Objects: {server_id: this.server_id, disk_id: String(disk.disk_id)},
              Configuration: {"HighSeverityThreshold": this.diskCapacityHighSeverity, "MediumSeverityThreshold": this.diskCapacityMediumSeverity, "LowSeverityThreshold": this.diskCapacityLowSeverity, "IsPercentage": this.diskCapacityIsPercentage}
            };
    
            if (this.diskCapacityHighSeverity > this.diskCapacityMediumSeverity || this.diskCapacityMediumSeverity > this.diskCapacityLowSeverity)
              {
                this.ShowFeedbackMessage(0);
                return;    
              } else
              {
                this.ShowFeedbackMessage(1)
        
        
                ////console.log(body)
                this.saveAlertToDB(JSON.stringify(body), alertType)
              }
              
           
          }
        )
        }
      
    
    const bodyAllDisks = {
      Objects: {server_id: this.server_id},
      Configuration: {"HighSeverityThreshold": this.diskCapacityHighSeverity, "MediumSeverityThreshold": this.diskCapacityMediumSeverity, "LowSeverityThreshold": this.diskCapacityLowSeverity, "IsPercentage": this.diskCapacityIsPercentage}
    };
    this.saveAlertToDB(JSON.stringify(bodyAllDisks), alertType)


    } else
    { //perform operation only on the selected alert
      const body = {
        Objects: {server_id: this.server_id, disk_id: this.selected_server_disk_id},
        Configuration: {"HighSeverityThreshold": this.diskCapacityHighSeverity, "MediumSeverityThreshold": this.diskCapacityMediumSeverity, "LowSeverityThreshold": this.diskCapacityLowSeverity, "IsPercentage": this.diskCapacityIsPercentage}
      };
  
      ////console.log(body)
      this.saveAlertToDB(JSON.stringify(body), alertType)
    }
    this.ShowFeedbackMessage(1);
    

    setTimeout(() => {    //give query time to finish on that side.
      this.resetAlerts();
    }, 200);

  
 
  }

  saveDiskCapacityDefault()
  {

    const alertType = 1
    const body = {
      Configuration: {"HighSeverityThreshold": this.diskCapacityHighSeverity, "MediumSeverityThreshold": this.diskCapacityMediumSeverity, "LowSeverityThreshold": this.diskCapacityLowSeverity, "IsPercentage": this.diskCapacityIsPercentage}
    };

    //console.log(body)
    if (this.diskCapacityHighSeverity > this.diskCapacityMediumSeverity || this.diskCapacityMediumSeverity > this.diskCapacityLowSeverity)
      {
        this.ShowFeedbackMessage(0);
        return;    
      } else
      {
        this.ShowFeedbackMessage(1)

        this.saveAlertToDB(JSON.stringify(body), alertType)
      }
      
  
 
    

    setTimeout(() => {    //give query time to finish on that side.
      this.resetAlerts();
    }, 400);
 
  }
  
  deleteAllCustomDiskCapacity()
  {
    const alertType = 1

    this.serverDisks.pop() //this will remove the "AllDisks" option from the array, as it is the last item in the array.
  
        this.serverDisks.forEach(disk => {
          ////console.log("doing item ", disk.disk_id)        //delete each disk's modification
  
          const body = {
            Objects: {server_id: this.server_id, disk_id: String(disk.disk_id)},
            Configuration: {"HighSeverityThreshold": this.diskCapacityHighSeverity, "MediumSeverityThreshold": this.diskCapacityMediumSeverity, "LowSeverityThreshold": this.diskCapacityLowSeverity, "IsPercentage": this.diskCapacityIsPercentage}
          };
      
          ////console.log(body)
          this.deleteAlertFromDB(JSON.stringify(body), alertType)
        });

        this.resetAlerts();
  }

  deleteDiskCapacityCustom()
  {
    const alertType = 1


    let bodyAllDisks;
    if (this.selected_server_disk_id == 99) //this is the "All Disks" option.
    {
      //loop for each item in serverDisks
     
      
      bodyAllDisks = {
        Objects: {server_id: this.server_id},
        Configuration: {"HighSeverityThreshold": this.diskCapacityHighSeverity, "MediumSeverityThreshold": this.diskCapacityMediumSeverity, "LowSeverityThreshold": this.diskCapacityLowSeverity, "IsPercentage": this.diskCapacityIsPercentage}
      }
    this.deleteAlertFromDB(JSON.stringify(bodyAllDisks), alertType)
    } else  //only delete the selected option
    {
      const body = {
        Objects: {server_id: this.server_id, disk_id: this.selected_server_disk_id},
        Configuration: {"HighSeverityThreshold": this.diskCapacityHighSeverity, "MediumSeverityThreshold": this.diskCapacityMediumSeverity, "LowSeverityThreshold": this.diskCapacityLowSeverity, "IsPercentage": this.diskCapacityIsPercentage}
      };
  
      ////console.log(body)
      this.deleteAlertFromDB(JSON.stringify(body), alertType)
  
    }


    
   
    this.ShowFeedbackMessage(1);
    

    setTimeout(() => {    //give query time to finish on that side.
      this.resetAlerts();
    }, 200);

  
  }
  loadDiskCapacityDefault(): void {
    ////console.log("Loading in default alerts")

//console.log('sending in trooper: ' +this.server_id)
    this.configService.GetDefaultAllRecordDisk(1, this.server_id).subscribe
    (
      (data: DiskCapacityAlertCustom) => 
        {
          //console.log(data)
          //console.log("DISK ID!: IN DEFAULT: " + this.server_id)
        if (data == null || data.server_id == null || data.server_id == 0)
          {

            //console.log("Pushing disk defaults")
            this.configService.getDiskCapacityAlert().subscribe(
              (data: DiskCapacityAlert) => {
                this.diskCapacityDefault = data;
                //console.log("default disk cap ", data)
        
                this.diskCapacityLowSeverity = this.diskCapacityDefault.lowSeverityThreshold
                this.diskCapacityMediumSeverity = this.diskCapacityDefault.mediumSeverityThreshold
                this.diskCapacityHighSeverity = this.diskCapacityDefault.highSeverityThreshold
                this.diskCapacityIsPercentage = this.diskCapacityDefault.isPercentage
              },
              error => {
                console.error('Error fetching default alert', error);
              }
            );
          } else
          {
            ////console.log("Pushing disk server speciifc defaults")
              this.diskCapacityDefault = data;
                //console.log(data)
        
                this.diskCapacityLowSeverity = this.diskCapacityDefault.lowSeverityThreshold
                this.diskCapacityMediumSeverity = this.diskCapacityDefault.mediumSeverityThreshold
                this.diskCapacityHighSeverity = this.diskCapacityDefault.highSeverityThreshold
                this.diskCapacityIsPercentage = this.diskCapacityDefault.isPercentage
          }
        }
    )

   
  }

  LoadDiskCapacityHelper(disk :DiskCapacityAlertCustom ) 
  {
    { 
        this.diskCapacityCustomExists = true;
        this.isModified = true

        this.diskCapacityLowSeverity = disk.lowSeverityThreshold
        this.diskCapacityMediumSeverity =disk.mediumSeverityThreshold
        this.diskCapacityHighSeverity = disk.highSeverityThreshold
        this.diskCapacityIsPercentage = disk.isPercentage
      }
  }

  onDiskChange(event)
  {
    ////console.log('LAODING NEW DISK')
    this.isModified = false;
    this.loadDiskCapacityCustom(this.server_id)
  }

  loadDiskCapacityCustom(server_id: number, sendToAllDisk: boolean = false): void {
    ////console.log("Loading in custom alerts");
  
    const old_disk = this.selected_server_disk_id;
    this.loadDisks();
    this.selected_server_disk_id = old_disk;
    ////console.log('old disk is set to ' + old_disk);
    
    this.configService.getDiskCapacityAlertCustom(server_id).subscribe(
      (data: DiskCapacityAlertCustom[]) => {
        ////console.log("custom alert processor data");
        ////console.log(data);
        this.diskCapacityCustom = data;
  
        let foundItem: Boolean = false;
        const thisBody = {
          Objects: { server_id: this.server_id },
        };
  
        this.configService.SearchForJSON(JSON.stringify(thisBody), 1).subscribe((result: boolean) => {
          ////console.log("Result:", result);
          this.isRecordOverwritten = result;
  
          ////console.log('data length is ' + data.length + " and serverDisk length is " + (this.serverDisks.length - 1));
  
          if (sendToAllDisk && this.isRecordOverwritten) {
            this.selected_server_disk_id = 99;
          }
  
          if (this.isRecordOverwritten && this.selected_server_disk_id == 99) {
            ////console.log('disk all found');
            ////console.log('found all disks to be equal', data[0]);
            foundItem = true;
            
            this.configService.GetDefaultAllRecordDisk(1, this.server_id).subscribe(
              response => {
                this.LoadDiskCapacityHelper(response);
             
              }
            )
           
          } else {
            for (let i = 0; i < data.length; i++) {
              ////console.log('disk: ' + (data[i].disk_id) + " " + this.selected_server_disk_id + " server: " + data[i].server_id + " " + this.server_id);
              if (this.selected_server_disk_id == (data[i].disk_id) && this.server_id == data[i].server_id) {
                foundItem = true;
                ////console.log('found the disk');
                this.LoadDiskCapacityHelper(data[i]);
              }
            }
          }
  
          if (!foundItem) {
            this.loadDiskCapacityDefault();
          }
        });
      }
    );
  }
  

  loadDisks()
  {
    this.configService.getDisks(this.server_id).subscribe(
      (data: getDisks[]) => {
        ////console.log('data of disks: ', data)
        this.serverDisks = data;
        this.serverDisks.push({ disk_id: 99, disk_label: 'All Disks' });

        if (this.selected_server_disk_id == 0)
          {
            this.selected_server_disk_id = this.serverDisks[0].disk_id
          }
      });
  }
  
  //Job Failure 
loadJobFailureDefault(): void {
  ////console.log("Loading in default alerts");

  this.configService.GetDefaultAllRecordJobFailure(2, this.server_id, this.instance_id).subscribe(
    (data: JobFailureAlertCustom) => {
      //console.log(data);
      //console.log("SERVER ID!: IN DEFAULT: " + data.server_id);
      if ( data == null ||  data.server_id == 0) {
        ////console.log("Pushing job defaults");
        this.configService.GetAlertJobFailure().subscribe(
          (data: JobFailureAlertCustom) => {
            this.jobFailureDefault = data;
            ////console.log(data);

            this.jobFailureSeverity = this.jobFailureDefault.severity;
            this.jobFailureAlertSeverityLevel = this.jobFailureDefault.alertSeverityLevel;
          },
          error => {
            console.error('Error fetching default alert', error);
          }
        );
      } else {
        ////console.log("Pushing job server specific defaults");
        this.jobFailureDefault = data;
        ////console.log(data);

        this.jobFailureSeverity = this.jobFailureDefault.severity;
        this.jobFailureAlertSeverityLevel = this.jobFailureDefault.alertSeverityLevel;
      }
    }
  );
}

loadJobFailureCustom(server_id: number, instance_id: number, sendToAllJob: boolean = false): void {
  ////console.log("Loading in custom alerts");

  const old_job = this.selected_job_id;
  this.loadJobs();
  this.selected_job_id = old_job;
  ////console.log('old job is set to ' + old_job);

  this.configService.GetAlertJobFailureCustom(server_id, instance_id).subscribe(
    (data: JobFailureAlertCustom[]) => {
      ////console.log("custom alert processor data");
      ////console.log(data);
      this.jobFailureCustom = data;

      let foundItem: Boolean = false;
      const thisBody = {
        Objects: { server_id: this.server_id, instance_id: this.instance_id },
      };

      this.configService.SearchForJSON(JSON.stringify(thisBody), 2).subscribe((result: boolean) => {
        ////console.log("Result:", result);
        this.isRecordOverwritten = result;

        ////console.log('data length is ' + data.length + " and instanceJobs length is " + (this.instanceJobs.length - 1));

        if (sendToAllJob && this.isRecordOverwritten) {
          this.selected_job_id = 99;
        }

        if (this.isRecordOverwritten && this.selected_job_id == 99) {
          ////console.log('jobs all found');
          ////console.log('found all jobs to be equal', data[0]);
          foundItem = true;

          this.configService.GetDefaultAllRecordJobFailure(2, this.server_id, this.instance_id).subscribe(
            response => {
              this.LoadJobFailureHelper(response);
            }
          );
        } else {
          for (let i = 0; i < data.length; i++) {
            //////console.log('job: ' + (data[i].job_id) + " " + this.selected_job_id + " instance: " + data[i].instance_id + " " + this.instance_id + " server: " + data[i].server_id + " " + this.server_id);
            if (this.selected_job_id == (data[i].job_id) && this.instance_id == data[i].instance_id && this.server_id == data[i].server_id) {
              foundItem = true;
              ////console.log('found the job');
              this.LoadJobFailureHelper(data[i]);
            }
          }
        }

        if (!foundItem) {
          this.loadJobFailureDefault();
        }
      });
    }
  );
}

deleteAllCustomJobFailure() :void
{
  const alertType = 2;

  this.instanceJobs.pop(); // this will remove the "AllJobs" option from the array, as it is the last item in the array.
 
    this.instanceJobs.forEach(job => {
      ////console.log("doing item ", job.sqlserver_agent_job_id); // delete each job's modification

      const body = {
        Objects: { server_id: this.server_id, instance_id: this.instance_id, job_id: Number(job.sqlserver_agent_job_id) },
        Configuration: { "Severity": this.jobFailureSeverity, "AlertSeverityLevel": this.jobFailureAlertSeverityLevel }
      };

      ////console.log(body);
      this.deleteAlertFromDB(JSON.stringify(body), alertType);
    });

    this.resetAlerts();
 
}

deleteJobFailureCustom(): void {
  const alertType = 2;

  let bodyAllJobs;
  if (this.selected_job_id == 99) // this is the "All Jobs" option
  {
   

    bodyAllJobs = {
      Objects: { server_id: this.server_id, instance_id: this.instance_id },
      Configuration: { "Severity": this.jobFailureSeverity, "AlertSeverityLevel": this.jobFailureAlertSeverityLevel }
    };
    this.deleteAlertFromDB(JSON.stringify(bodyAllJobs), alertType);
  } else {
    const body = {
      Objects: { server_id: this.server_id, instance_id: this.instance_id, job_id: this.selected_job_id },
      Configuration: { "Severity": this.jobFailureSeverity, "AlertSeverityLevel": this.jobFailureAlertSeverityLevel }
    };

    ////console.log(body);
    this.deleteAlertFromDB(JSON.stringify(body), alertType);
  }

  this.ShowFeedbackMessage(1);
    

  setTimeout(() => {
    this.resetAlerts();
  }, 200);
}

saveJobFailureCustom(): void {
  const alertType = 2;

  if (this.selected_job_id == 99) // this is the "All Jobs" option
  {
    this.instanceJobs.pop(); // this will remove the "AllJobs" option from the array, as it is the last item in the array.

    if (this.isApplyToAll) {
      this.instanceJobs.forEach(job => {
        ////console.log("doing item ", job.sqlserver_agent_job_id);
        const body = {
          Objects: { server_id: this.server_id, instance_id: this.instance_id, job_id: Number(job.sqlserver_agent_job_id) },
          Configuration: { "Severity": this.jobFailureSeverity, "AlertSeverityLevel": this.jobFailureAlertSeverityLevel }
        };

        ////console.log(body);
        this.saveAlertToDB(JSON.stringify(body), alertType);
      });
    }

    const bodyAllJobs = {
      Objects: { server_id: this.server_id, instance_id: this.instance_id },
      Configuration: { "Severity": this.jobFailureSeverity, "AlertSeverityLevel": this.jobFailureAlertSeverityLevel }
    };
    this.saveAlertToDB(JSON.stringify(bodyAllJobs), alertType);

  } else {
    const body = {
      Objects: { server_id: this.server_id, instance_id: this.instance_id, job_id: Number(this.selected_job_id) },
      Configuration: { "Severity": this.jobFailureSeverity, "AlertSeverityLevel": this.jobFailureAlertSeverityLevel }
    };

    ////console.log(body);
    this.saveAlertToDB(JSON.stringify(body), alertType);
  }

  this.ShowFeedbackMessage(1);
    

  setTimeout(() => {
    this.resetAlerts();
  }, 200);
}

  

  saveJobFailureDefault() {
    const alertType = 2;
    const body = {
      Configuration: { "Severity": this.jobFailureSeverity, "AlertSeverityLevel": this.jobFailureAlertSeverityLevel }
    };
  
    this.saveAlertToDB(JSON.stringify(body), alertType);
  
    this.ShowFeedbackMessage(1);
    
  
    setTimeout(() => { this.resetAlerts(); }, 200);
  }
  

  

  LoadJobFailureHelper(job :JobFailureAlertCustom )
  {
    ////console.log(job)
        this.jobFailureCustomExists = true;
        this.isModified = true

        this.jobFailureAlertSeverityLevel = job.alertSeverityLevel;
        this.jobFailureSeverity = job.severity
 
  }

  onJobFailureChange()
  {
    ////console.log('LAODING NEW FAILURE')
    this.isModified = false;
    this.loadJobFailureCustom(this.server_id, this.instance_id)
  }

  

 

  loadJobs()
  {
    this.configService.getJobs(this.server_id, this.instance_id).subscribe(
      (data: getJobs[]) => {
        //////console.log('data of jobs: ', data)
        this.instanceJobs = data;
        this.instanceJobs.push({ sqlserver_agent_job_id: 99, name: 'All Jobs' });
      

        if (this.selected_job_id == 0)
          {
            this.selected_job_id = this.instanceJobs[0].sqlserver_agent_job_id
          }
      });
  }


   //Overdue Full Backup
   saveFullBackupCustom(): void {
    const alertType = 9;
  
    if (this.selected_full_database_id == 99) // this is the "All Databases" option
    {
      this.instanceDatabases.pop(); // this will remove the "AllDatabases" option from the array, as it is the last item in the array.
  
      if (this.isApplyToAll) {
        this.instanceDatabases.forEach(database => {
          ////console.log("doing item ", database.database_id);
          const body = {
            Objects: { server_id: this.server_id, instance_id: this.instance_id, database_id: Number(database.database_id) },
            Configuration: { "Severity": Number(this.fullBackupSeverity), "SeverityDuration": Number(this.fullBackupSeverityDuration) }
          };
  
          ////console.log(body);
          this.saveAlertToDB(JSON.stringify(body), alertType);
        });
      }
  
      const bodyAllDatabases = {
        Objects: { server_id: this.server_id, instance_id: this.instance_id },
        Configuration: { "Severity": Number(this.fullBackupSeverity), "SeverityDuration": Number(this.fullBackupSeverityDuration) }
      };
      this.saveAlertToDB(JSON.stringify(bodyAllDatabases), alertType);
  
    } else {
      const body = {
        Objects: { server_id: this.server_id, instance_id: this.instance_id, database_id: Number(this.selected_full_database_id) },
        Configuration: { "Severity": Number(this.fullBackupSeverity), "SeverityDuration": Number(this.fullBackupSeverityDuration) }
      };
  
      ////console.log(body);
      this.saveAlertToDB(JSON.stringify(body), alertType);
    }
  
    this.ShowFeedbackMessage(1);
    
  
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }
   saveFullBackupDefault()
   {
 
     const alertType = 9

     this.fullBackupSeverity = Number(this.fullBackupSeverity);
     this.fullBackupSeverityDuration = Number(this.fullBackupSeverityDuration);

     const body = {
       Configuration: {"Severity": this.fullBackupSeverity, "SeverityDuration": this.fullBackupSeverityDuration}
     };
 
     ////console.log(body)
     this.saveAlertToDB(JSON.stringify(body), alertType)
 
 
     this.ShowFeedbackMessage(1);
    
 
     setTimeout(() => {    //give query time to finish on that side.
       this.resetAlerts();
     }, 400);
  
   }



   deleteAllCustomFullBackup() :void 
   {
    const alertType = 9;

    this.instanceDatabases.pop(); // this will remove the "AllDatabases" option from the array, as it is the last item in the array.
 
      this.instanceDatabases.forEach(database => {
        ////console.log("doing item ", database.database_id); // delete each database's modification

        const body = {
          Objects: { server_id: this.server_id, instance_id: this.instance_id, database_id: Number(database.database_id) },
          Configuration: { "Severity": Number(this.fullBackupSeverity), "SeverityDuration": Number(this.fullBackupSeverityDuration) }
        };

        ////console.log(body);
        this.deleteAlertFromDB(JSON.stringify(body), alertType);
      });
    

      this.resetAlerts();
      
   }



   deleteFullBackupCustom(): void {
    const alertType = 9;
  
    let bodyAllDatabases;
    if (this.selected_full_database_id == 99) // this is the "All Databases" option
    {
      
  
      bodyAllDatabases = {
        Objects: { server_id: this.server_id, instance_id: this.instance_id },
        Configuration: { "Severity": Number(this.fullBackupSeverity), "SeverityDuration": Number(this.fullBackupSeverityDuration) }
      };
      this.deleteAlertFromDB(JSON.stringify(bodyAllDatabases), alertType);
    } else {
      const body = {
        Objects: { server_id: this.server_id, instance_id: this.instance_id, database_id: Number(this.selected_full_database_id) },
        Configuration: { "Severity": Number(this.fullBackupSeverity), "SeverityDuration": Number(this.fullBackupSeverityDuration) }
      };
  
      ////console.log(body);
      this.deleteAlertFromDB(JSON.stringify(body), alertType);
    }
  
    this.ShowFeedbackMessage(1);
    
  
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }
    
  loadFullBackupDefault(): void {
    ////console.log("Loading in default alerts for full backup");
  
    this.configService.GetDefaultAllRecordFullBackup(9, this.server_id, this.instance_id).subscribe(
      (data: FullBackupAlertCustom) => {
        ////console.log(data);
        ////console.log("SERVER ID!: IN DEFAULT: " + data.server_id);
        if (data == null || data.server_id == 0) {
          ////console.log("Pushing full backup defaults");
          this.configService.GetAlertFullBackups().subscribe(
            (data: FullBackupAlertCustom) => {
              this.fullBackupDefault = data;
              ////console.log(data);
  
              this.fullBackupSeverity = this.fullBackupDefault.severity;
              this.fullBackupSeverityDuration = this.fullBackupDefault.severityDuration;
            },
            error => {
              console.error('Error fetching default alert', error);
            }
          );
        } else {
          ////console.log("Pushing full backup server specific defaults");
          this.fullBackupDefault = data;
          ////console.log(data);
  
          this.fullBackupSeverity = this.fullBackupDefault.severity;
          this.fullBackupSeverityDuration = this.fullBackupDefault.severityDuration;
        }
      }
    );
  }
 
   LoadFullBackupHelper(backup :FullBackupAlertCustom )
   {
         this.fullBackupCustomExists = true;
         this.isModified = true
 
         this.fullBackupSeverity = backup.severity
         this.fullBackupSeverityDuration = backup.severityDuration  
   }
 
   onFullBackupChange()
   {
     ////console.log('Loading new full backup')
     this.isModified = false;
     this.loadFullBackupCustom(this.server_id, this.instance_id)
   }
 
   loadFullBackupCustom(server_id: number, instance_id: number, sendToAllDatabase: boolean = false): void {
    ////console.log("Loading in custom alerts for full backup");
  
    const old_database = this.selected_full_database_id;
    this.loadFullBackup();
    this.selected_full_database_id = old_database;
    ////console.log('old database is set to ' + old_database);
  
    this.configService.GetAlertFullBackupsCustom(server_id, instance_id).subscribe(
      (data: FullBackupAlertCustom[]) => {
        ////console.log("custom alert processor data");
        ////console.log(data);
        this.fullBackupCustom = data;
  
        let foundItem: Boolean = false;
        const thisBody = {
          Objects: { server_id: this.server_id, instance_id: this.instance_id },
        };
  
        this.configService.SearchForJSON(JSON.stringify(thisBody), 9).subscribe((result: boolean) => {
          ////console.log("Result:", result);
          this.isRecordOverwritten = result;
  
          ////console.log('data length is ' + data.length + " and instanceDatabases length is " + (this.instanceDatabases.length - 1));
  
          if (sendToAllDatabase && this.isRecordOverwritten) {
            this.selected_full_database_id = 99;
          }
  
          if (this.isRecordOverwritten && this.selected_full_database_id == 99) {
            ////console.log('databases all found');
            ////console.log('found all databases to be equal', data[0]);
            foundItem = true;
  
            this.configService.GetDefaultAllRecordFullBackup(9, this.server_id, this.instance_id).subscribe(
              response => {
                this.LoadFullBackupHelper(response);
              }
            );
          } else {
            for (let i = 0; i < data.length; i++) {
              ////console.log('database: ' + (data[i].database_id) + " " + this.selected_full_database_id + " instance: " + data[i].instance_id + " " + this.instance_id + " server: " + data[i].server_id + " " + this.server_id);
              if (this.selected_full_database_id == (data[i].database_id) && this.instance_id == data[i].instance_id && this.server_id == data[i].server_id) {
                foundItem = true;
                ////console.log('found the database');
                this.LoadFullBackupHelper(data[i]);
              }
            }
          }
  
          if (!foundItem) {
            this.loadFullBackupDefault();
          }
        });
      }
    );
  }

   onDatabaseChangeFullBackup()
   {
    ////console.log('LAODING NEW DATABASE FULL BACKUPS')
    this.isModified = false;
    this.loadFullBackupCustom(this.server_id, this.instance_id)
   }

   deleteAllCustomLogBackup() :void 
   {
    const alertType = 11;

    this.instanceLogDatabases.pop(); // this will remove the "AllDatabases" option from the array, as it is the last item in the array.
 
      this.instanceLogDatabases.forEach(database => {
        ////console.log("doing item ", database.database_id); // delete each database's modification

        const body = {
          Objects: { server_id: this.server_id, instance_id: this.instance_id, database_id: Number(database.database_id) },
          Configuration: { 
            highSeverityThreshold: Number(this.logBackupHighSeverityThreshold),
            mediumSeverityThreshold: Number(this.logBackupMediumSeverityThreshold),
            lowSeverityThreshold: Number(this.logBackupLowSeverityThreshold),
            severityDuration: Number(this.logBackupSeverityDuration),
          }
        };

        ////console.log(body);
        this.deleteAlertFromDB(JSON.stringify(body), alertType);
      });
    

      this.resetAlerts();
      
   }

   onDatabaseChangeLogBackup()
   {
    ////console.log('LAODING NEW DATABASE LOG BACKUPS')
    this.isModified = false;
    this.loadLogBackupCustom(this.server_id, this.instance_id)
   }
 
   loadFullBackup()
   {
     this.configService.GetDatabase(this.server_id, this.instance_id).subscribe(
       (data: Database[]) => {
         ////console.log('data of full backups: ', data)
         this.instanceDatabases = data;
         this.instanceDatabases.push({database_id: 99, instance_id: this.instance_id, name: 'All Databases'}) 
 
         if (this.selected_full_database_id == 0)
           {
             this.selected_full_database_id = this.instanceDatabases[0].database_id
           }
       });
   }

   loadLogBackup()
   {
     this.configService.GetLogDatabase(this.server_id, this.instance_id).subscribe(
       (data: Database[]) => {
         ////console.log('data of full backups: ', data)
         this.instanceLogDatabases = data;
         this.instanceLogDatabases.push({database_id: 99, instance_id: this.instance_id, name: 'All Databases'}) 
 
         if (this.selected_log_database_id == 0)
           {
             this.selected_log_database_id = this.instanceLogDatabases[0].database_id
           }
       });
   }
   
//monitor heartbeat
  loadMonitorHeartbeatDefault(): void {
    ////console.log("Loading in default alerts - calling monitor heartbeat default")

    this.configService.GetAlertMonitorHeartbeat().subscribe(
      (data: MonitorHeartbeatAlert) => {
        this.monitorHeartbeatDefault = data;
        ////console.log("logging monitor default...!!")
        ////console.log(data)
        
        ////console.log(this.monitorHeartbeatDefault.severity)
        this.monitorHeartbeatSeverity = this.monitorHeartbeatDefault.severity
        ////console.log(this.monitorHeartbeatSeverity)
        this.monitorHeartbeatSeverityDuration = this.monitorHeartbeatDefault.severityDuration
        this.monitorHeartbeatAlertSeverityLevel = this.monitorHeartbeatDefault.alertSeverityLevel


      },
      error => {
        console.error('Error fetching default alert', error);
      }
    );
  }

  loadMonitorHeartbeatCustom(licence_key :string): void {
    ////console.log("Loading in custom alerts")
    this.configService.GetAlertMonitorHeartbeatCustom(licence_key).subscribe(
      (data: MonitorHeartbeatAlertCustom) => {
        ////console.log("custom monitor alert processor data")
        ////console.log(data)
        this.monitorHeartbeatCustom = data;

        if (this.monitorHeartbeatCustom.licence_key == '') //no custom found. Instead, go load defaults.
        {
          ////console.log("not found licence_key. Instead, loading defaults.")
          this.loadMonitorHeartbeatDefault()

        } else
        { 
            this.monitorHeartbeatCustomExists = true;
            this.isModified = true

            this.monitorHeartbeatSeverity = this.monitorHeartbeatCustom.severity
            this.monitorHeartbeatSeverityDuration = this.monitorHeartbeatCustom.severityDuration
            this.monitorHeartbeatAlertSeverityLevel = this.monitorHeartbeatCustom.alertSeverityLevel
          }
        }
    )
    }

    deleteMonitorHeartbeatCustom()
    {
      const body = {
        Objects: {licence_key: this.licence_key},
        Configuration: {"Severity": this.monitorHeartbeatSeverity, "SeverityDuration": this.monitorHeartbeatSeverityDuration, "AlertSeverityLevel": this.monitorHeartbeatAlertSeverityLevel}
      };
  
      ////console.log(body)
      this.deleteAlertFromDB(JSON.stringify(body), 7)
  
   
      this.ShowFeedbackMessage(1);
    
  
      setTimeout(() => {    //give query time to finish on that side.
        this.resetAlerts();
      }, 200);
   
    }

  saveMonitorHeartbeatCustom()
  {
    const body = {
      Objects: {licence_key: this.licence_key},
      Configuration: {"Severity": this.monitorHeartbeatSeverity, "SeverityDuration": this.monitorHeartbeatSeverityDuration, "AlertSeverityLevel": this.monitorHeartbeatAlertSeverityLevel}
    };

    ////console.log(body)
  
    this.saveAlertToDB(JSON.stringify(body), 7)

    
    this.ShowFeedbackMessage(1);
    

    setTimeout(() => {    //give query time to finish on that side.
      this.resetAlerts();
    }, 200);

   
  }

  saveMonitorHeartbeatDefault()
  {
    const body = {
      Configuration: {"Severity": this.monitorHeartbeatSeverity, "SeverityDuration": this.monitorHeartbeatSeverityDuration, "AlertSeverityLevel": this.monitorHeartbeatAlertSeverityLevel}
    };

    ////console.log(body)
    this.saveAlertToDB(JSON.stringify(body), 7)

    this.ShowFeedbackMessage(1);
    
   

    setTimeout(() => {    //give query time to finish on that side.
      this.resetAlerts();
    }, 400);

   
  }

  //Error log
  loadErrorLogDefault(): void {
    ////console.log("Loading in default alerts");
  
    this.configService.getErrorLogAlert().subscribe(
      (data: ErrorLogAlert) => {
        ////console.log(data);
  
        this.errorLogHighSeverityThreshold = data.highSeverityThreshold;
        this.errorLogMediumSeverityThreshold = data.mediumSeverityThreshold;
        this.errorLogLowSeverityThreshold = data.lowSeverityThreshold;
        this.errorLogSuppressionDuration = data.suppressionDuration;
      },
      error => {
        console.error('Error fetching default alert', error);
      }
    );
  }
  
  loadErrorLogCustom(server_id: number, instance_id: number): void {
    ////console.log("Loading in custom alerts");
  
    this.configService.getErrorLogAlertCustom(server_id, instance_id).subscribe(
      (data: ErrorLogAlertCustom[]) => {
        ////console.log("custom alert processor data");
        ////console.log(data);
        this.errorLogCustom = data;
  
        let foundItem: Boolean = false;
  
        for (let i = 0; i < data.length; i++) {
          if (this.instance_id == data[i].instance_id && this.server_id == data[i].server_id) {
            foundItem = true;
            ////console.log('found the alert');
            this.loadErrorLogHelper(data[i]);
          }
        }
  
        if (!foundItem) {
          this.loadErrorLogDefault();
        }
      }
    );
  }
  
  deleteErrorLogCustom(): void {
    const alertType = 4;
  
    const body = {
      Objects: { server_id: this.server_id, instance_id: this.instance_id },
      Configuration: { 
        highSeverityThreshold: this.errorLogHighSeverityThreshold,
        mediumSeverityThreshold: this.errorLogMediumSeverityThreshold,
        lowSeverityThreshold: this.errorLogLowSeverityThreshold,
        suppressionDuration: this.errorLogSuppressionDuration 
      }
    };
  
    ////console.log(body);
    this.deleteAlertFromDB(JSON.stringify(body), alertType);
  
    this.ShowFeedbackMessage(1);
    
  
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }
  
  saveErrorLogCustom(): void {
    const alertType = 4;
  
    const body = {
      Objects: { server_id: this.server_id, instance_id: this.instance_id },
      Configuration: { 
        highSeverityThreshold: this.errorLogHighSeverityThreshold,
        mediumSeverityThreshold: this.errorLogMediumSeverityThreshold,
        lowSeverityThreshold: this.errorLogLowSeverityThreshold,
        suppressionDuration: this.errorLogSuppressionDuration 
      }
    };
  

    if (this.errorLogHighSeverityThreshold < this.errorLogMediumSeverityThreshold || this.errorLogMediumSeverityThreshold < this.errorLogLowSeverityThreshold)
      {
        this.ShowFeedbackMessage(0);
        return;    
      } else
      {
        this.saveAlertToDB(JSON.stringify(body), alertType);
  
        this.ShowFeedbackMessage(1);
        
      }

    ////console.log(body);
   
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }
  
  saveErrorLogDefault(): void {
    const alertType = 4;
    const body = {
      Configuration: { 
        highSeverityThreshold: this.errorLogHighSeverityThreshold,
        mediumSeverityThreshold: this.errorLogMediumSeverityThreshold,
        lowSeverityThreshold: this.errorLogLowSeverityThreshold,
        suppressionDuration: this.errorLogSuppressionDuration 
      }
    };
  
    if (this.errorLogHighSeverityThreshold < this.errorLogMediumSeverityThreshold || this.errorLogMediumSeverityThreshold < this.errorLogLowSeverityThreshold)
      {
        this.ShowFeedbackMessage(0);
        return;    
      } else
      {
        this.saveAlertToDB(JSON.stringify(body), alertType);
  
        this.ShowFeedbackMessage(1);
        
      }
  
    setTimeout(() => { this.resetAlerts(); }, 200);
  }
  
  loadErrorLogHelper(alert: ErrorLogAlertCustom): void {
    ////console.log(alert)
    this.errorLogCustomExists = true;
    this.isModified = true;
  
    this.errorLogHighSeverityThreshold = alert.highSeverityThreshold;
    this.errorLogMediumSeverityThreshold = alert.mediumSeverityThreshold;
    this.errorLogLowSeverityThreshold = alert.lowSeverityThreshold;
    this.errorLogSuppressionDuration = alert.suppressionDuration;
  }

  //blocking

  loadBlockingDefault(): void {
    ////console.log("Loading in default alerts");
  
    this.configService.getBlockingAlert().subscribe(
      (data: BlockingAlert) => {
        ////console.log(data);
  
        this.blockingHighSeverityDuration = data.highSeverityDuration;
        this.blockingMediumSeverityDuration = data.mediumSeverityDuration;
        this.blockingLowSeverityDuration = data.lowSeverityDuration;
      },
      error => {
        console.error('Error fetching default alert', error);
      }
    );
  }
  
  loadBlockingCustom(server_id: number, instance_id: number): void {
    ////console.log("Loading in custom alerts");
  
    this.configService.getBlockingAlertCustom(server_id, instance_id).subscribe(
      (data: BlockingAlertCustom[]) => {
        ////console.log("custom alert processor data");
        ////console.log(data);
        this.blockingCustom = data;
  
        let foundItem: Boolean = false;
  
        for (let i = 0; i < data.length; i++) {
          if (this.instance_id == data[i].instance_id && this.server_id == data[i].server_id) {
            foundItem = true;
            ////console.log('found the alert');
            this.loadBlockingHelper(data[i]);
          }
        }
  
        if (!foundItem) {
          this.loadBlockingDefault();
        }
      }
    );
  }
  
  deleteBlockingCustom(): void {
    const alertType = 5;
  
    const body = {
      Objects: { server_id: this.server_id, instance_id: this.instance_id },
      Configuration: { 
        highSeverityDuration: this.blockingHighSeverityDuration,
        mediumSeverityDuration: this.blockingMediumSeverityDuration,
        lowSeverityDuration: this.blockingLowSeverityDuration
      }
    };
  
    ////console.log(body);
    this.deleteAlertFromDB(JSON.stringify(body), alertType);
  
    this.ShowFeedbackMessage(1);
    
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }
  
  saveBlockingCustom(): void {
    const alertType = 5;
  
    const body = {
      Objects: { server_id: this.server_id, instance_id: this.instance_id },
      Configuration: { 
        highSeverityDuration: this.blockingHighSeverityDuration,
        mediumSeverityDuration: this.blockingMediumSeverityDuration,
        lowSeverityDuration: this.blockingLowSeverityDuration
      }
    };
  
    if (this.blockingHighSeverityDuration < this.blockingMediumSeverityDuration || this.blockingMediumSeverityDuration < this.blockingLowSeverityDuration)
      {
        this.ShowFeedbackMessage(0);
        return;    
      } else
      {
      ////console.log(body);
      this.saveAlertToDB(JSON.stringify(body), alertType);
    
      this.ShowFeedbackMessage(1);
        
      }
    
    
  
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }
  
  saveBlockingDefault(): void {
    const alertType = 5;
    const body = {
      Configuration: { 
        highSeverityDuration: this.blockingHighSeverityDuration,
        mediumSeverityDuration: this.blockingMediumSeverityDuration,
        lowSeverityDuration: this.blockingLowSeverityDuration
      }
    };
  
    if (this.blockingHighSeverityDuration < this.blockingMediumSeverityDuration || this.blockingMediumSeverityDuration < this.blockingLowSeverityDuration)
      {
        this.ShowFeedbackMessage(0);
        return;    
      } else
      {
      ////console.log(body);
      this.saveAlertToDB(JSON.stringify(body), alertType);
    
      this.ShowFeedbackMessage(1);
        
      }
  
    setTimeout(() => { this.resetAlerts(); }, 200);
  }
  
  loadBlockingHelper(alert: BlockingAlertCustom): void {
    ////console.log(alert)
    this.blockingCustomExists = true;
    this.isModified = true;
  
    this.blockingHighSeverityDuration = alert.highSeverityDuration;
    this.blockingMediumSeverityDuration = alert.mediumSeverityDuration;
    this.blockingLowSeverityDuration = alert.lowSeverityDuration;
  }

  //log backup
  loadLogBackupDefault(): void {
    this.configService.GetDefaultAllRecordLogBackup(11, this.server_id, this.instance_id).subscribe(
      (data: LogBackupAlertCustom) => {
        if (data == null || data.server_id == 0) {
          this.configService.getLogBackupAlert().subscribe(
            (defaultData: LogBackupAlert) => {
              this.logBackupHighSeverityThreshold = defaultData.highSeverityThreshold;
              this.logBackupMediumSeverityThreshold = defaultData.mediumSeverityThreshold;
              this.logBackupLowSeverityThreshold = defaultData.lowSeverityThreshold;
              this.logBackupSeverityDuration = defaultData.severityDuration;
            },
            error => {
              console.error('Error fetching default alert', error);
            }
          );
        } else {
          this.logBackupHighSeverityThreshold = data.highSeverityThreshold;
          this.logBackupMediumSeverityThreshold = data.mediumSeverityThreshold;
          this.logBackupLowSeverityThreshold = data.lowSeverityThreshold;
          this.logBackupSeverityDuration = data.severityDuration;
        }
      },
      error => {
        console.error('Error fetching default record', error);
      }
    );
  }
  
  loadLogBackupCustom(server_id: number, instance_id: number, sendToAllDatabase: boolean = false): void {
    const old_database = this.selected_log_database_id;
    this.loadLogBackup();
    this.selected_log_database_id = old_database;
  
    this.configService.getLogBackupAlertCustom(server_id, instance_id).subscribe(
      (data: LogBackupAlertCustom[]) => {
        this.logBackupCustom = data;

        //console.log(this.logBackupCustom)
  
        let foundItem: Boolean = false;
        const thisBody = {
          Objects: { server_id: this.server_id, instance_id: this.instance_id },
        };
  
        this.configService.SearchForJSON(JSON.stringify(thisBody), 11).subscribe((result: boolean) => {
          this.isRecordOverwritten = result;
  
          if (sendToAllDatabase && this.isRecordOverwritten) {
            this.selected_log_database_id = 99;
          }
  
          if (this.isRecordOverwritten && this.selected_log_database_id == 99) {
            foundItem = true;
  
            this.configService.GetDefaultAllRecordLogBackup(11, this.server_id, this.instance_id).subscribe(
              response => {
                //console.log("ALL LOGS!! DATABASE SELECTION = 99", response)
                this.loadLogBackupHelper(response);
              }
            );
          } else {
            for (let i = 0; i < data.length; i++) {
              if (this.selected_log_database_id == (data[i].database_id) && this.instance_id == data[i].instance_id && this.server_id == data[i].server_id) {
                foundItem = true;
                this.loadLogBackupHelper(data[i]);
              }
            }
          }
  
          if (!foundItem) {
            this.loadLogBackupDefault();
          }
        });
      }
    );
  }
  
  
  deleteLogBackupCustom(): void {
    const alertType = 11;
  
    let bodyAllDatabases;
    if (this.selected_log_database_id == 99) // this is the "All Databases" option
    {
      bodyAllDatabases = {
        Objects: { server_id: this.server_id, instance_id: this.instance_id },
        Configuration: { 
          highSeverityThreshold: this.logBackupHighSeverityThreshold,
          mediumSeverityThreshold: this.logBackupMediumSeverityThreshold,
          lowSeverityThreshold: this.logBackupLowSeverityThreshold,
          severityDuration: this.logBackupSeverityDuration
        }
      };
      this.deleteAlertFromDB(JSON.stringify(bodyAllDatabases), alertType);
    } else {
      const body = {
        Objects: { server_id: this.server_id, instance_id: this.instance_id, database_id: Number(this.selected_log_database_id) },
        Configuration: { 
          highSeverityThreshold: this.logBackupHighSeverityThreshold,
          mediumSeverityThreshold: this.logBackupMediumSeverityThreshold,
          lowSeverityThreshold: this.logBackupLowSeverityThreshold,
          severityDuration: this.logBackupSeverityDuration
        }
      };
  
      this.deleteAlertFromDB(JSON.stringify(body), alertType);
    }
  
    this.ShowFeedbackMessage(1);
  
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }
  
  
  saveLogBackupCustom(): void {
    const alertType = 11;
  
    if (this.selected_log_database_id == 99) { // this is the "All Databases" option
      this.instanceLogDatabases.pop(); // remove the "AllDatabases" option from the array, as it is the last item in the array.
  
      if (this.isApplyToAll) {
        this.instanceLogDatabases.forEach(database => {
          const body = {
            Objects: { server_id: this.server_id, instance_id: this.instance_id, database_id: Number(database.database_id) },
            Configuration: { 
              highSeverityThreshold: this.logBackupHighSeverityThreshold,
              mediumSeverityThreshold: this.logBackupMediumSeverityThreshold,
              lowSeverityThreshold: this.logBackupLowSeverityThreshold,
              severityDuration: this.logBackupSeverityDuration
            }
          };
  
          this.saveAlertToDB(JSON.stringify(body), alertType);
        });
      }
  
      const bodyAllDatabases = {
        Objects: { server_id: this.server_id, instance_id: this.instance_id },
        Configuration: { 
          highSeverityThreshold: this.logBackupHighSeverityThreshold,
          mediumSeverityThreshold: this.logBackupMediumSeverityThreshold,
          lowSeverityThreshold: this.logBackupLowSeverityThreshold,
          severityDuration: this.logBackupSeverityDuration
        }
      };
      this.saveAlertToDB(JSON.stringify(bodyAllDatabases), alertType);
  
    } else {
      const body = {
        Objects: { server_id: this.server_id, instance_id: this.instance_id, database_id: Number(this.selected_log_database_id) },
        Configuration: { 
          highSeverityThreshold: this.logBackupHighSeverityThreshold,
          mediumSeverityThreshold: this.logBackupMediumSeverityThreshold,
          lowSeverityThreshold: this.logBackupLowSeverityThreshold,
          severityDuration: this.logBackupSeverityDuration
        }
      };
  
      this.saveAlertToDB(JSON.stringify(body), alertType);
    }
  
    this.ShowFeedbackMessage(1);
  
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }
  
  
  saveLogBackupDefault(): void {
    const alertType = 11;
    const body = {
      Configuration: { 
        highSeverityThreshold: this.logBackupHighSeverityThreshold,
        mediumSeverityThreshold: this.logBackupMediumSeverityThreshold,
        lowSeverityThreshold: this.logBackupLowSeverityThreshold,
        severityDuration :this.logBackupSeverityDuration,
      }
    };
  
    if (this.logBackupHighSeverityThreshold < this.logBackupMediumSeverityThreshold || this.logBackupMediumSeverityThreshold < this.logBackupLowSeverityThreshold)
      {
        this.ShowFeedbackMessage(0);
        return;    
      } else
      {
        this.saveAlertToDB(JSON.stringify(body), alertType);
  
        this.ShowFeedbackMessage(1);
      }
    
  
    setTimeout(() => { this.resetAlerts(); }, 200);
  }
  
  loadLogBackupHelper(alert: LogBackupAlertCustom): void {
    ////console.log(alert)
    this.logBackupCustomExists = true;
    this.isModified = true;
  
    this.logBackupHighSeverityThreshold = alert.highSeverityThreshold;
    this.logBackupMediumSeverityThreshold = alert.mediumSeverityThreshold;
    this.logBackupLowSeverityThreshold = alert.lowSeverityThreshold;
    this.logBackupSeverityDuration = alert.severityDuration;
  }
  loadAvailabilityGroupHealthDefault(): void {
    ////console.log("Loading in default alerts");
  
    this.configService.getAvailabilityGroupHealthAlert().subscribe(
      (data: AvailabilityGroupHealthAlert) => {
        //console.log(data);
  
        this.availabilityGroupHighSeverityValue = data.highSeverityValue;
        this.availabilityGroupMediumSeverityValue = data.mediumSeverityValue;
        this.availabilityGroupLowSeverityValue = data.lowSeverityValue;
      },
      error => {
        console.error('Error fetching default alert', error);
      }
    );
  }
  
  loadAvailabilityGroupHealthCustom(server_id: number, instance_id: number): void {
    ////console.log("Loading in custom alerts");
  
    this.configService.getAvailabilityGroupHealthAlertCustom(server_id, instance_id).subscribe(
      (data: AvailabilityGroupHealthAlertCustom[]) => {
        ////console.log("custom alert processor data");
        //console.log(data);
        this.availabilityGroupCustom = data;
  
        let foundItem: Boolean = false;
  
        for (let i = 0; i < data.length; i++) {
          if (this.instance_id == data[i].instance_id && this.server_id == data[i].server_id) {
            foundItem = true;
            ////console.log('found the alert');
            this.loadAvailabilityGroupHealthHelper(data[i]);
          }
        }
  
        if (!foundItem) {
          this.loadAvailabilityGroupHealthDefault();
        }
      }
    );
  }
  
  deleteAvailabilityGroupHealthCustom(): void {
    const alertType = 13;
  
    const body = {
      Objects: { server_id: this.server_id, instance_id: this.instance_id },
      Configuration: { 
        HighSeverityValue: this.availabilityGroupHighSeverityValue,
        MediumSeverityValue: this.availabilityGroupMediumSeverityValue,
        LowSeverityValue: this.availabilityGroupLowSeverityValue,        
      }
    };
  
    ////console.log(body);
    this.deleteAlertFromDB(JSON.stringify(body), alertType);
  
    this.ShowFeedbackMessage(1);
    
  
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }
  
  saveAvailabilityGroupHealthCustom(): void {
    const alertType = 13;
  
    const body = {
      Objects: { server_id: this.server_id, instance_id: this.instance_id },
      Configuration: { 
        HighSeverityValue: this.availabilityGroupHighSeverityValue,
        MediumSeverityValue: this.availabilityGroupMediumSeverityValue,
        LowSeverityValue: this.availabilityGroupLowSeverityValue,
      }
    };

    if (this.availabilityGroupHighSeverityValue > this.availabilityGroupMediumSeverityValue)
      {
        this.ShowFeedbackMessage(0);
        return;    
      } else
      {
       ////console.log(body);
        this.saveAlertToDB(JSON.stringify(body), alertType);
        this.ShowFeedbackMessage(1);
        
      }
  
  
    
    setTimeout(() => {
      this.resetAlerts();
    }, 200);
  }
  
  saveAvailabilityGroupHealthDefault(): void {
    const alertType = 13;
    const body = {
      Configuration: { 
        HighSeverityValue: this.availabilityGroupHighSeverityValue,
        MediumSeverityValue: this.availabilityGroupMediumSeverityValue,
        LowSeverityValue: this.availabilityGroupLowSeverityValue,
      }
    };
  
    if (this.availabilityGroupHighSeverityValue < this.availabilityGroupMediumSeverityValue || this.availabilityGroupMediumSeverityValue < this.availabilityGroupLowSeverityValue)
      {
        this.ShowFeedbackMessage(0);
        return;    
      } else
      {
       ////console.log(body);
        this.saveAlertToDB(JSON.stringify(body), alertType);
        this.ShowFeedbackMessage(1);
        
      }
  
    setTimeout(() => { this.resetAlerts(); }, 200);
  }
  
  loadAvailabilityGroupHealthHelper(alert: AvailabilityGroupHealthAlertCustom): void {
    ////console.log(alert)
    this.availabilityGroupCustomExists = true;
    this.isModified = true;
  
    this.availabilityGroupHighSeverityValue = alert.highSeverityValue;
    this.availabilityGroupMediumSeverityValue = alert.mediumSeverityValue;
    this.availabilityGroupLowSeverityValue = alert.lowSeverityValue;
  }
  

  
}
