import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
 export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private _router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.status === 400) {
            window.alert('There was a problem with your username or password. Please try again.');
        }
        if (error.status === 500 ) {
          window.alert('There was a server error. Please contact your administrator.');
          this._router.navigate(['login']);
        }
        if (error.status === 0 ) {
          window.alert('The connection has been refused by the server. Please contact your server admin');
          this._router.navigate(['login']);
        }
        if (error.status ===  502) {
          window.alert('There was a connection error. Routing you back to the login page...');
          this._router.navigate(['login']);
        } 
        if (error.status ===  401) {
        
          console.log("login status " + localStorage.getItem('loggedIn'))

          if (localStorage.getItem('loggedIn') === null ) 
          {
            window.alert('Your session has expired. Routing you back to login...');
            localStorage.setItem('loggedIn', 'false')   //show only once per login to avoid spamming user with popups
            this._router.navigate(['login']);
          }
         
        } 
        else {
            window.alert('There was an unexpected error. Routing you back to login...');
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            this._router.navigate(['login']);
          }
          return throwError(errorMessage);
        })
      );
  }
 }
