﻿import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { IServerStatus, IAlert } from './dashboard';
import { DashboardService } from './dashboard.service';
//import { DashboardComponent } from './dashboard.component';

@Component({
    selector: 'server-card',
    templateUrl: './servercard.component.html',
    styleUrls: ['./servercard.component.css'],
})

export class ServerStatusComponent {
    @Input() servers: IServerStatus[];
    @Input() alerts: IAlert[];

    alertLevelFilter: number[];

    @Output()
    clearErrorEvent: EventEmitter<string> = new EventEmitter<string>();

    alertIndex: number = -1;
    importance: string;

    topAlert :number;

    constructor(private _dashboardService: DashboardService) { }

    postClearAlert(id: number): void {
        this._dashboardService.postClearAlert(id).subscribe(data => { this.clearErrorEvent.emit('refresh'); });
    }

    ngOnInit()
    {
      this._dashboardService.getTopAlert().subscribe(data => {
        this.topAlert = data[0].id
    });
    
    }
    
   postLogTicket(id: number, alertLevel: number): void {

      if (alertLevel == 0) {
        this.importance = 'high';
      }
      if (alertLevel == 1) {
        this.importance = 'medium';
      }
      if (alertLevel == 2) {
        this.importance = 'low';
      } else {
        this.importance = 'healthy';
      }

      this._dashboardService.postLogTicket(id, this.importance).subscribe();
      this._dashboardService.postClearAlert(id).subscribe(data => { this.clearErrorEvent.emit('refresh'); });
  }
    trackByServerId(index: number, server: IServerStatus): number {
        return server.id;
    }

    trackByAlertId(index: number, alert: IAlert): number {
        return alert.id;
    }

    onToggleAlerts(index: number): void {
        if (this.alertIndex == index) {
            this.alertIndex = -1;
        } else {
            this.alertIndex = index;
        }
    }

    onFilterChange(filter: number[]): void {
        this.alertLevelFilter = filter;
        this.alertLevelFilter.sort();
    }

 }
