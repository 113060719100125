// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //webApiRoot: 'http://10.0.0.4:5001/'
 webApiRoot: 'https://monitor.sqlsupport.co.za/api/'     //PROD
  //webApiRoot: 'http://102.133.174.147:5000/'
  //webApiRoot: 'http://10.0.0.9/'
 // webApiRoot: 'http://localhost:5002/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impactch
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
