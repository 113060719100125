import { Component } from '@angular/core';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { AuthService } from './services/auth.service';
import { Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'my-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})

export class AppComponent  {
}
