﻿import { Component, OnInit } from '@angular/core';
import { IServerStatus, IAlert, IAlertSummary } from './dashboard';
import { DashboardService } from './dashboard.service';
import { Router, ActivatedRoute} from '@angular/router';
import { AuthService } from '../services/auth.service';



@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
})

export class DashboardComponent implements OnInit {

    servers: IServerStatus[];
    alerts: IAlert[];
    alertSummary: IAlertSummary[];
    interval: any;

    constructor(private _router: Router, private _dashboardService: DashboardService, private _authService: AuthService) { }

    ngOnInit() {
        this.refreshData();
        this.interval = setInterval(() => {
            // if no token exist on client, force to login page
            if (localStorage.getItem('token') == null) {
                this._router.navigate(['login']);
                /* if (!this._authService.isAuthenticated()){
                    this._router.navigate(['login']);
                    }  // See login.component.ts for more info  */
            }
            else {
                this.refreshData();
           }
        }, 60000);
    }


    refreshData() {
        this._dashboardService.getServerStatus()
            .subscribe((serverData) => this.servers = serverData);

       this._dashboardService.getAlerts()
            .subscribe((alertData) => this.alerts = alertData);

        this._dashboardService.getAlertSummary()
            .subscribe((alertData) => this.alertSummary = alertData);
      
    }
}
