import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl, TreeControl } from '@angular/cdk/tree';
import { ConfigNavigationServiceService } from './config-navigation-service.service';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

interface TreeNode {
  name: string;
  type?: string;
  children?: TreeNode[];
  expanded: boolean ;
  searchProperty :string;
}

@Component({
  selector: 'config-navigation',
  templateUrl: './config-navigation.component.html',
  styleUrls: ['./config-navigation.component.css']
})
export class ConfigNavigationComponent implements OnInit {
  treeControl ;
  dataSource = new MatTreeNestedDataSource<TreeNode>();
  dataSourceHeader = new MatTreeNestedDataSource<TreeNode>();

  selectedNode: any;
  selectedNodeType :any;
  selectedNodeSearchProperty;

  reload$ :Subscription;

  private latestNode :any;

  private sectionHistory :TreeNode[] = [];

  constructor(private configService: ConfigNavigationServiceService, private zone: NgZone, private changeDetectorRef: ChangeDetectorRef) {}
 
  newOrgName :string = 'New Organisation';

  private extraNodeRendered :boolean = true;

  expandedNodes :TreeNode[] = [];
  previousNodes :TreeNode[] = [];

  contextMenuVisible = false;
  contextMenuPosition = { x: 0, y: 0 };
  contextMenuType: string;
  

  renderHeaderNode = (index: number, node: TreeNode) => {
    if (!this.extraNodeRendered && index === 0) {   
        this.extraNodeRendered = true;
        return true;
    }
    return false;
}

toggleStyle(treeNodeRef: TreeNode) {
  ////////console.log('toggling style for ' + treeNodeRef.name)
  this.selectedNode = treeNodeRef;


}
onRightClick(event: MouseEvent, node: any): void {
  event.preventDefault();
  event.stopPropagation();  // Stop the event from propagating up the DOM tree

  this.contextMenuType = this.getNodeType(node);
  this.contextMenuPosition = { x: event.clientX , y: event.clientY   };
  this.contextMenuVisible = true;
}


@HostListener('document:mousedown', ['$event'])
  onDocumentClick(event: MouseEvent): void {
   
    const targetElement = event.target as HTMLElement; 
    const clickedInsideMenu = targetElement.closest('.custom-context-menu') !== null;

    if (!clickedInsideMenu) { 
      this.contextMenuVisible = false;  // Close the context menu
    }
  }


getNodeType(node: any): string {
 
  return node.type;
}

createNewNode(): void {
  this.contextMenuVisible = false;
  if (this.contextMenuType === 'organisation') {
    this.createNewOrg();
  } else if (this.contextMenuType === 'license') {
    this.createNewLicense(this.selectedNode.organization, this.selectedNode.organization_name);
  } else if (this.contextMenuType === 'server') {
    this.createNewServer(this.selectedNode.organisation_id, this.selectedNode.license_key, this.selectedNode.is_active, this.selectedNode.organization_name, this.selectedNode.license_name);
  } else if (this.contextMenuType === 'instance') {
    this.createNewInstance(this.selectedNode.server_id, this.selectedNode.organization_name, this.selectedNode.license_name, this.selectedNode.serverName);
  }
}

collapseChildren(node :TreeNode) :void
{ //collapse children and remove them from the expanded list of nodes if their parent is collapsed

  if (node.expanded == false)
    {
      if (node.children)
        {
          node.children.forEach(child => {
            this.collapseChildren(child)
          });
        } else
        {
          this.treeControl.collapse(node)
          node.expanded = false;
          this.expandedNodes.splice(this.expandedNodes.indexOf(node), 1)
        }
    }
} 

expandPeers(parent :TreeNode) :void {

  //Takes the parent to toggle nodes on the level it was called. eg: server will call license, license will call organisaton.

  parent.children.forEach(child =>
    {
      this.treeControl.expand(child)
      ////console.log('expanding peers for ' + child.name)
    }
  
  )
  
}

toggleChildren(node: any): void {

  //////console.log("Beginning toggle of all children. ")
  //////console.log(this.expandedNodes)
  node.children.forEach(child => {
    if (child) {
      ////////console.log('Node name:', child.name);
      this.treeControl.toggle(child);
      //this.collapseChildren(child);
      
      this.toggleExpanded(child);     
  } else {
      ////////console.log('No children available');
  }
  });
}
toggleExpanded(node :TreeNode) :void
{
  if (this.expandedNodes.find(n => n.searchProperty === node.searchProperty))
  {
    //nodes already exists. Delete this node and it's children
    node.expanded = false
    
    ////console.log('false array from deletion', node)
    this.deleteChildrenFromExpanded(node)
    this.expandedNodes = this.expandedNodes.filter(n => {
      if (n.searchProperty != node.searchProperty) {
          return true;
      } else {
          n.expanded = false;
          return false;
      }
  });
  
    this.expandedNodes = this.removeDuplicates(this.expandedNodes)
    
    ////console.log('new expandedNodes array is ', this.expandedNodes)
    
  } else
  {
    //insert into array
    node.expanded = true
    ////console.log('inserting to array', node)
    this.expandedNodes.push(node)
    ////console.log('new expandedNodes array is ', this.expandedNodes)
    //////console.log("After insert")
    //////console.log(this.expandedNodes)
    
  }
}

deleteChildrenFromExpanded(node) :void
{ //delete node's children from the expanded nodes array
  if (node.children)
    {
      node.children.forEach(child => {
        child.expanded = false;
        if (this.expandedNodes.find(n => n.searchProperty == child.searchProperty))
          {
            this.expandedNodes = this.expandedNodes.filter(node => {
              if (node.searchProperty != child.searchProperty) {
                  return true;
              } else {
                  node.expanded = false;
                  return false;
              }
          });
          
            this.expandedNodes = this.expandedNodes.filter(node => node != undefined && node != null);

            this.deleteChildrenFromExpanded(child)
          } else
          {
            ////console.log('Was unable to find a node with ' + child.searchProperty)
          }
      });
    }
}

    
/*

      this.dataSource.data.forEach(node => {
        this.sectionHistory.forEach(nodeH => {
          //////////console.log('node was', nodeH)
          if (node.name == nodeH.name)
          {          
            node.children.forEach(child => {
              if (child.name == nodeH.name)
              {
                this.treeControl.expand(child);
              }
              if (child.children != null)
              {
                child.children.forEach(child => {
                  if (child.name == nodeH.name)
                  {
                    this.treeControl.expand(child);
                  }
                
              })
              }
            });
            ////////console.log(node)
            
          }
        
        });

      });*/

    
  

  toggleMe(node: TreeNode): void {
    
    //this.treeControl.expand(node); 
      node.expanded = !node.expanded;

      ////console.log("acting on ", node)

      if (this.expandedNodes.some(n => n.searchProperty === node.searchProperty)) 
        {
          //Delete this top-level node
          this.expandedNodes = this.expandedNodes.filter(n => n.searchProperty !== node.searchProperty);

          ////console.log('new expandedNode after deletion of top node: ', this.expandedNodes)
          
        } else
        {//insert this top-level node
          this.expandedNodes.push(node)
          ////console.log('pushing expanded: ', this.expandedNodes)
        } 
     
    this.toggleChildren(node) 
    this.reload()
  
    
     
  }

  reload()
  {
    const now = new Date();
    const oneSecondFuture= new Date(now.getTime() + 1000);

    if (!(this.configService.lastReload  > oneSecondFuture)) //if auto reload will hit in less than 1 second, don't auto reload.
    {
      this.configService.triggerReload();
    }
  }

  

removeDuplicates(nodes: TreeNode[]): TreeNode[] {
  const seen = new Set();
  return nodes.filter(node => {
    const duplicate = seen.has(node.searchProperty);
    seen.add(node.searchProperty);
    return !duplicate;
  });
}
ngOnInit(): void {
  this.treeControl = new NestedTreeControl<TreeNode>(node => node ? node.children || [] : []);

  
  this.configService.tabSelected$.subscribe((tab) => {

    if (tab.tabName != 'Details')
      {
        this.selectedNode = null;
      }
  });
  
  this.configService.getreload$().pipe(   //begins reload event observer
    switchMap(() => this.configService.getOrganisationData())
  ).subscribe(data => {
    ////////console.log('reloading');
    ////////console.log(data)
     this.previousNodes = this.treeControl.dataNodes
    if (data) { 
      this.dataSource.data = data;
      this.dataSourceHeader.data = [data[0]]
      this.treeControl.dataNodes = data;

  } else {
      console.error("Received undefined or null data");
  }

    setTimeout(() => {

      if (this.expandedNodes == null)
        {
          return;
        }
        
      ////////console.log('expanded nodes are', expandedNodes)
      this.dataSource.data = JSON.parse(JSON.stringify(data));
      this.treeControl.dataNodes = this.dataSource.data;
      setTimeout(() => {this.restoreExpandedNodes(this.expandedNodes)
        this.expandedNodes = this.removeDuplicates(this.expandedNodes)
        
        const nodeN = this.findNode(this.treeControl.dataNodes, this.selectedNodeSearchProperty, this.selectedNodeType)
        
        ////console.log('nodeN was ' + nodeN)
        this.selectedNode = nodeN;

      }, 0);
      
      
    }, 0);
   

})
} 

findNode(treeNodes, searchProp, type :string) {
  for (const node of treeNodes) {

    if (node.searchProperty == searchProp) {
      return node;
    }
    if (node.children && node.children.length) {
      const foundNode = this.findNode(node.children, searchProp, type);
      if (foundNode) {
        return foundNode;
      }
    }
  }
  return null;
}



saveExpandedNodes(): TreeNode[] {
  return this.treeControl.dataNodes.filter(node => node.expanded);
}
updatedExpandedNodes(expandedNodes :TreeNode[]) :void
{
  //finds expanded nodes in the new dataNodes by matching against name, and sets the state of the dataNodes to true where a match is found.
  //sets the state of these nodes to expanded.
  
 if (expandedNodes == null)
  {
    return;
  }

this.expandedNodes.forEach(expNode => {
  
  let node = this.findNode(this.treeControl.dataNodes, expNode.searchProperty, expNode.type)

  if (node)
    {
      //////console.log('expanding: ' + node.name)
      this.treeControl.expand(node)
    }
    else{
      //////console.log('failed: ' + expNode.name)
    }
});


  
  expandedNodes.forEach(expandedNode =>{

    let node;
 
    node = this.findNode(this.treeControl.dataNodes, expandedNode.searchProperty, expandedNode.type)
    if (node && expandedNode.expanded)
      {
        ////////console.log('expanded nodes at time of run', expandedNodes)
        //////console.log(node.name + " was expanded with an expansion value of " +  expandedNode.expanded)
        node.expanded = true;
    
        if (expandedNode.children)
          {
            expandedNode.children.forEach(child => {
              this.updatedExpandedNodes([child])
            });
          }  
      } else
      {
        if (node && !node.expanded)
          {
            //////console.log("the node: " + node.name + " was set to unexpanded.")
            // node.expanded = true;

          }
      }
    
  })
}


restoreExpandedNodes(expandedNodes: TreeNode[]): void {
  setTimeout(() => {
    ////console.log('beginning nodes:', expandedNodes)
    ////console.log('previous nodes', this.previousNodes)
    this.updatedExpandedNodes(expandedNodes)
    setTimeout(() => {

      this.treeControl.dataNodes.forEach(node => {

        if (node == null)
          {
            return;
          }
          if ((node.expanded && this.expandedNodes.some(n => n.searchProperty === node.searchProperty))) {
            this.treeControl.expand(node);
            this.expandPeers(node)
            this.expandChildren(node);
            ////console.log('expanding the node that is named ', node.name)
        } 
      });
   
    },50);
   
  }, 0); 

  ////console.log('ending nodes:', expandedNodes)
}

expandChildren(node) :void
{
  if (node)
    {
      if (node.children && node.children.length > 0)
        {
          ////////console.log('expanding node\'s kids ', node)
          node.children.forEach(child => {
            ////////console.log('child ', child)
            ////console.log('expansion value of ' + child.name,child.expanded)
            if (child.expanded)
              {
                this.treeControl.expand(node)
                this.expandPeers(node)
                this.expandChildren(child)
               
              }
          });
        } else
        {
          ////////console.log('expanding child- the child is ', node)
          this.treeControl.expand(node)
        }
    }
 
}


selectItem(child: any): void {
//console.log('entering selitem')
  //if (event.button == 0) { 
    //console.log('selected button is lmb')
  
    this.configService.setPageState(child)
    //this.toggleExpanded(child)

    this.selectedNode = child
  
    this.selectedNodeType = child.type;
    this.selectedNodeSearchProperty = child.searchProperty;
    ////console.log('nodeN = ',  child.searchProperty)
 // }
 

}

createNewOrg()
{
  var childNode = {
  name: '',
  btNet: 0,
  type: 'organisation',
  isNew:true,
}

this.configService.setPageState(childNode)

}
createNewLicense(organisation_id, organization_name)
{
  var childNode = {
    active: true,
    description: '',
    name: uuidv4(),
    organization: organisation_id,
    type: 'license',
    enable: true,
    organization_name: organization_name,
    isNew: true

  }
  //////console.log(childNode)
  this.configService.setPageState(childNode)
  ////////console.log('new license')
}

createNewServer(org_id, licence_key, is_active, organization_name, license_name)
{
  var childNode = {
    organisation_id: org_id,
    name: '',
    type: 'server',
    license_key: licence_key,
    is_active:is_active,
    isNew :true,
    organization_name: organization_name,
    license_name :license_name

  }
  this.configService.setPageState(childNode)
  ////////console.log('new server')
}


createNewInstance(server_id, organization_name, license_name, serverName)
{

  const currentDateTime = new Date().toISOString();
  const collection_date = currentDateTime.substring(0, 16); // truncates to collection_date format


  var childNode = {
    collection_date: collection_date,
    name: 'MSSQLSERVER',
    port: null,
    server_id: server_id,
    sqlserver_instance_id: 0,
    type: 'instance',
    isNew:true,
    organization_name: organization_name,
    license_name : license_name,
    serverName : serverName

  }

  ////////console.log('serverid for the new instance was ', childNode)
  this.configService.setPageState(childNode)
  ////////console.log('new instance')
}


  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

  hasLicenses = (_: number, node: TreeNode) => !!node.children && node.children.some(child => child.type === 'license');
  hasServers = (_: number, node: TreeNode) => !!node.children && node.children.some(child => child.type === 'server');
  hasInstances = (_: number, node: TreeNode) => !!node.children && node.children.some(child => child.type === 'instance');
  hasGroup = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

 
}
