import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { RouterModule, Routes, ActivatedRoute, Router } from '@angular/router';

import { DashboardService } from './dashboard/dashboard.service';
import { RegistrationService } from './registration/registration.service';
import { AlertService } from './alert/alert.service';

import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ServerStatusComponent } from './dashboard/servercard.component';
import { AlertSummaryComponent } from './dashboard/alertsummary.component';
import { DashboardFilterComponent } from './dashboard/dashboardfilter.component';

import { AlertListComponent } from './alert/alertlist.component';
import { AlertDetailComponent } from './alert/alertdetail.component';

import { LoginComponent } from './login/login.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guard/auth-guard';
import { HttpErrorInterceptor } from './services/token.interceptor';

import { MaterialModule } from '../app/material.module';

import { RegistrationComponent } from './registration/registration.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatStepperModule, MatInputModule, MatButtonModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ConfigComponent } from './config/config.component';
import { ConfigNavigationComponent } from './config-navigation/config-navigation.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatTreeModule} from '@angular/material/tree'
import { ConfigNavigationServiceService } from './config-navigation/config-navigation-service.service';
import { MatIconModule } from '@angular/material/icon';
import { ConfigPageDisplayComponent } from './config-page-display/config-page-display.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

const navRoutes: Routes = [
  // canAvtivate: [AuthGuard] must be added to any route that must be protected from access. The component's .ts must also be setup with authguard.
    { path: 'alert/alertdetail/:id', component: AlertDetailComponent  , canActivate: [AuthGuard] },
    { path: 'alert/:id/:sid', component: AlertListComponent  , canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent  , canActivate: [AuthGuard] },
    { path: 'config', component: ConfigComponent},
    { path: 'alert', component: AlertListComponent , canActivate: [AuthGuard]},
    { path: 'register', component: RegistrationComponent},
    { path: 'login', component: LoginComponent },
    //last 3 lines of routes direct any url's that do not match one set in the code, directly to the login page.
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: '*', redirectTo: '/login', pathMatch: 'full' },
    { path: '**', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  exports: [MatTreeModule, MatIconModule, ConfigNavigationComponent, ConfigPageDisplayComponent],
    imports: [CommonModule, BrowserModule, BrowserAnimationsModule, MatDialogModule, FormsModule, ReactiveFormsModule, HttpClientModule, MaterialModule, BrowserAnimationsModule,MatTreeModule,
      MatStepperModule, MatInputModule, MatButtonModule, RouterModule.forRoot(navRoutes, { useHash: true }) ],
    declarations: [AppComponent, DashboardComponent, ServerStatusComponent, AlertSummaryComponent, DashboardFilterComponent, AlertListComponent, AlertDetailComponent, LoginComponent, RegistrationComponent, ConfigComponent, ConfigNavigationComponent, ConfigPageDisplayComponent, ConfirmationDialogComponent ],
    providers: [DashboardService, AlertService, AuthService, RegistrationService, ConfigNavigationServiceService, {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true
    }],
  bootstrap:    [ AppComponent ],
  entryComponents: [ConfirmationDialogComponent]
})
export class AppModule { }
