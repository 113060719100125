import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute} from '@angular/router';
import { invalid } from '@angular/compiler/src/render3/view/util';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  invalidLogin: boolean;


  constructor(private _router: Router, private _auth: AuthService, private _authService: AuthService) { }

  ngOnInit() {
    // Ensure no token is sitting in cache when the login page is hit. Mainly just as a logout function.
    localStorage.clear();
  }

  onSubmit(credentials: any) {
    this._auth.verifyCredentials(credentials).subscribe(result => {
      if (result) {
        this._router.navigate(['dashboard']);
      } else  {
        this.invalidLogin = false;
      }
    });
  }
}
