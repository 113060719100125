﻿import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { IAlert } from './alert';
import { AlertService } from './alert.service';
import {Location} from '@angular/common';

@Component({
    selector: 'alert-detail',
    templateUrl: './alertdetail.component.html',
    styleUrls: ['./alertdetail.component.css'],
})

export class AlertDetailComponent {

    interval: any;
    alert: IAlert;

    id: number;
    sub: any;

    constructor(private _alertService: AlertService, private _Activatedroute: ActivatedRoute, private _router: Router, private _location: Location) { }





    ngOnInit() {
      this.refreshAlerts();
      this.interval = setInterval(() => {
          // if no token exist on client, force to login page
          if (localStorage.getItem('token') == null) {
              this._router.navigate(['login']);
              /* if (!this._authService.isAuthenticated()){
                  this._router.navigate(['login']);
                  }  // See login.component.ts for more info  */
          } else {
              this.refreshAlerts();
         }
      }, 60000);
  }

  backClicked() {
    this._location.back();
  }

    refreshAlerts() {
      this.sub = this._Activatedroute.params.subscribe(params => {
        this.id = params['id'];
    });

    this._alertService.getAlertDetail(this.id)
        .subscribe((alertData) => this.alert = alertData[0]);
    }

    clearAlert()
    {
        this._alertService.postClearAlert(this.alert.id).subscribe(data => { this._location.back(); });
    }

}
