import { Injectable, ErrorHandler } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Register } from './registration';
import { environment } from '../../environments/environment';

@Injectable()
export class RegistrationService {

    constructor (private _http: HttpClient) {}

    getRegister(registerForm: any): Observable<Register[]> {
      const httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
      const options = {headers: httpHeaders};
      return this._http.post<Register[]>(environment.webApiRoot + 'api/register', JSON.stringify(registerForm), options)
          .catch(this.handleError);
  }

      //return this._http.post<number>(environment.webApiRoot + "api/register", JSON.stringify(org_id, company_name, contact_firstname, contact_lastname, contact_email, address_line1, address_line2, address_city, address_province, address_country, address_postcode, post_line1, post_line2, post_city, post_province, post_country, post_postcode: decimal , tel_countrycode: decimal , tel_mobile: decimal , tel_office: decimal , tel_extension: decimal , terms_conditions: Boolean , password: string), options);

    handleError(error: Response) {
        console.error(error);

        return Observable.throw(error);
    }
}

