import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})


// Passes route requests to AuthGuard to verify the clients token.
// On succeed the routes are set to activated.
// On fail the client is redirected to the login page.
export class AuthGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) {
  }
  if
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

  if (this._authService.isAuthenticated()) {
    return true;
  }
  else {
    this._router.navigate(['/login'])
        }
  }
}
