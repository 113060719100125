import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { IAlert, IAlertSummary, IServerStatus } from 'src/app/dashboard/dashboard';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { ConfigNavigationServiceService } from '../config-navigation/config-navigation-service.service';

export interface TreeNode {
  name: string;
  children?: TreeNode[];
}

export interface token
{
  token: string;
}

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {
  @ViewChild('navigationContainer') navigationContainer: ElementRef;
  @ViewChild('resizeHandle') resizeHandle: ElementRef;
  isResizing: boolean = false;
  
  servers: IServerStatus[];
  alerts: IAlert[];
  alertSummary: IAlertSummary[];
  interval: any;

  isDropdownOpen = false;

  selectedTab :string = 'Organisation'

  constructor(private _router: Router, private _dashboardService: DashboardService, private _authService: AuthService, private _http: HttpClient, private configService: ConfigNavigationServiceService) { }

  ngOnInit() {
 
   /*this.interval = setInterval(() => {
        // if no token exist on client, force to login page
        if (localStorage.getItem('token') == null) {
            this._router.navigate(['login']);
        }
    }, 60000);*/

    this.interval = setInterval(() => {   //force refresh of config data every 30 seconds
      
     this.configService.autoReload();
  }, 30000);
}
toggleDropdown(event: Event) {
  event.preventDefault();
  this.isDropdownOpen = !this.isDropdownOpen;
}

handleTabClick(tabName: string, subtab: string, event: Event) {
  this.selectTab(tabName, subtab);
  this.isDropdownOpen = false; // Close the dropdown after selection
}

 

@HostListener('document:click', ['$event'])
handleClickOutside(event: Event) {
  const target = event.target as HTMLElement;
  const clickedInsideDropdown = target.closest('.dropdown');
  if (!clickedInsideDropdown) {
    this.isDropdownOpen = false;
  }
}
selectTab(tabName: string, subtab :string) {
  this.configService.selectTab(tabName, subtab);
} 

@HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isResizing) {
      return;
    }
    const containerWidth = event.clientX - this.navigationContainer.nativeElement.getBoundingClientRect().left;
    this.navigationContainer.nativeElement.style.width = `${containerWidth}px`;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    if (this.isResizing) {
      this.isResizing = false;
    }
  }

  startResizing(event: MouseEvent) {
    event.preventDefault(); // Prevent text selection during resize
    this.isResizing = true;
  }
}
