﻿import { Component, Input } from '@angular/core';
import { IAlertSummary } from './dashboard';

@Component({
    selector: 'alert-note',
    templateUrl: './alertsummary.component.html',
    styleUrls: ['./alertsummary.component.css']
})

export class AlertSummaryComponent {
    @Input() alerts: IAlertSummary[];

    constructor() { }

    trackBySource(index: number, alert: IAlertSummary): string {
        return alert.alertSource;
    }
}
